.emb-campaignblock {
  color: #fff;
  position: relative;
  min-height: 517px;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
}
html.cooper .emb-campaignblock {
  background-color: #001d60;
}
html.avon .emb-campaignblock {
  background-color: #fff;
}
.emb-campaignblock .contentwrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  height: 100%;
  flex: 1;
}
.emb-campaignblock__title {
  font-size: 50px;
  line-height: 45px;
  margin: 0 0 24px;
  max-width: 282px;
}
html.cooper .emb-campaignblock__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .emb-campaignblock__title {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
.emb-campaignblock__description {
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  max-width: 352px;
}
.emb-campaignblock__link-container {
  padding: 50px 0 0;
}
.emb-campaignblock__overlay, .emb-campaignblock__bgimage, .emb-campaignblock__fgimage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.emb-campaignblock__overlay {
  background-image: linear-gradient(90deg, #001D60 14%, rgba(0, 29, 96, 0) 100%);
}
.emb-campaignblock__fgimage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 768px) {
  .emb-campaignblock__fgimage {
    display: none;
  }
}
html.isie .emb-campaignblock .contentwrapper {
  display: block;
}
html.isie .emb-campaignblock__link-container {
  padding: 100px 0 0;
}
html[dir=rtl] .emb-campaignblock__bgimage, html[dir=rtl] .emb-campaignblock__overlay {
  transform: scaleX(-1);
}
html.avon .emb-campaignblock__overlay {
  background-image: linear-gradient(90deg, #000 14%, rgba(0, 29, 96, 0) 100%);
}