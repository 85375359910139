.sponsorship .contentwrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.sponsorship__items {
  min-height: 415px;
  position: relative;
}
.sponsorship__item {
  width: calc(25% - 21px);
}
.sponsorship__image-bg {
  display: block;
  height: 270px;
  margin: 0 0 45px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.24);
}
.sponsorship__image-mob {
  display: none;
}
.sponsorship__logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sponsorship__logo-img {
  height: 50px;
}
html.cooper .sponsorship__items::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: #F4F5F7;
  z-index: -1;
}
html.avon .sponsorship {
  position: relative;
}
html.avon .sponsorship__logo-img {
  height: 30px;
}
html.avon .sponsorship__items {
  min-height: 390px;
}
html.avon .sponsorship:before, html.avon .sponsorship:after {
  content: "";
  z-index: -1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 225px;
  background-color: #f4f5f7;
}
@media (max-width: 768px) {
  html.avon .sponsorship {
    padding-top: 30px;
    margin-top: -20px;
  }
  html.avon .sponsorship:before {
    height: 100%;
  }
}
html.avon .sponsorship:before {
  left: 0;
  right: 0;
}
html.avon .sponsorship:after {
  left: 70%;
  right: -100px;
  background: transparent url(../../web/img/avon-pattern.png) 0 0 repeat;
}
@media (max-width: 768px) {
  .sponsorship__items::after {
    display: none;
  }
  .sponsorship__item {
    width: 100%;
    padding-left: 16.667vw;
    padding-right: 16.667vw;
    position: relative;
    margin-bottom: 20px;
  }
  .sponsorship__item::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 13px;
    right: 13px;
    height: 150px;
    background: #F4F5F7;
    z-index: -1;
  }
  .sponsorship__image-bg {
    background: none !important;
    height: auto;
    box-shadow: 0;
    margin: 0;
  }
  .sponsorship__image-mob {
    display: block;
    width: 100%;
  }
  .sponsorship__logo-container {
    padding: 25px 0 32px;
  }
  .sponsorship .contentwrapper {
    padding-left: 0;
    padding-right: 0;
  }
}