.emb-lineup {
  padding-left: 13px;
  padding-right: 13px;
}
.emb-lineup__categories {
  margin-bottom: 70px;
}
.emb-lineup__category {
  display: none;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  justify-content: space-between;
  flex-flow: row wrap;
}
html.avon .emb-lineup__category {
  max-width: 1440px;
}
.emb-lineup__category.enabled {
  display: flex;
}
.emb-lineup__category--align-center {
  justify-content: center;
}
.emb-lineup__category--align-center .emb-lineup__tirecontainer {
  margin-left: 8px;
  margin-right: 8px;
}
.emb-lineup__category--align-left {
  justify-content: flex-start;
}
.emb-lineup__category--align-left .emb-lineup__tirecontainer {
  margin-right: 15px;
}
.emb-lineup__linebreak {
  display: none;
}
.emb-lineup__tirecontainer {
  width: calc(25% - 21px);
}
html.avon .emb-lineup__tirecontainer {
  width: calc(25% - 18px);
}
.emb-lineup__bottom {
  margin: 0 auto;
  width: 100%;
  max-width: 728px;
  display: flex;
  justify-content: space-between;
}
.emb-lineup__bottomheading {
  width: 100%;
  max-width: 282px;
  font-size: 50px;
  line-height: 45px;
  letter-spacing: 1px;
  padding-right: 10px;
}
html.cooper .emb-lineup__bottomheading {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .emb-lineup__bottomheading {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
html.cooper .emb-lineup__bottomheading {
  color: #001d60;
}
html.avon .emb-lineup__bottomheading {
  color: #fff;
}
.emb-lineup__bottomtextcontainer {
  width: 100%;
  max-width: 352px;
}
.emb-lineup__bottomtext {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 30px;
}
.emb-lineup__bottomlinkcontainer {
  display: flex;
  align-items: center;
}
.emb-lineup__bottomlinkcontainer a,
.emb-lineup__bottomlinkcontainer .circlearrow {
  display: block;
}
.emb-lineup__bottomlink {
  margin-right: 24px;
}
html[dir=rtl] .emb-lineup__bottomlink {
  margin-left: 24px;
  margin-right: 0;
}
.emb-lineup__bottomlink-title {
  font-size: 24px;
  line-height: 100%;
  text-decoration: none;
  letter-spacing: 1.33px;
}
html.cooper .emb-lineup__bottomlink-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .emb-lineup__bottomlink-title {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
html.cooper .emb-lineup__bottomlink-title {
  color: #001d60;
}
html.avon .emb-lineup__bottomlink-title {
  color: #fff;
}
html.non-touch .emb-lineup__bottomlink-title:hover {
  text-decoration: underline;
}
.emb-lineup__mobileslideshow {
  display: none;
}
.emb-lineup[data-aos=my-animation] .lineup__title,
.emb-lineup[data-aos=my-animation] .lineup__tabs,
.emb-lineup[data-aos=my-animation] .emb-lineup__tirecontainer,
.emb-lineup[data-aos=my-animation] .emb-lineup__bottom {
  opacity: 0;
  transform: translateY(-70px);
  transition-property: transform, opacity;
}
.emb-lineup[data-aos=my-animation] .emb-lineup__bottom {
  transform: none;
}
.emb-lineup[data-aos=my-animation].aos-animate .lineup__title,
.emb-lineup[data-aos=my-animation].aos-animate .lineup__tabs,
.emb-lineup[data-aos=my-animation].aos-animate .emb-lineup__tirecontainer,
.emb-lineup[data-aos=my-animation].aos-animate .emb-lineup__bottom {
  opacity: 1;
  transform: none;
  transition-duration: 0.5s;
}
.emb-lineup[data-aos=my-animation].aos-animate .lineup__tabs {
  transition-delay: 0.15s;
}
.emb-lineup[data-aos=my-animation].aos-animate .emb-lineup__tirecontainer {
  transition-delay: 0.3s;
}
.emb-lineup[data-aos=my-animation].aos-animate .emb-lineup__tirecontainer:nth-child(2) {
  transition-delay: 0.35s;
}
.emb-lineup[data-aos=my-animation].aos-animate .emb-lineup__tirecontainer:nth-child(4) {
  transition-delay: 0.4s;
}
.emb-lineup[data-aos=my-animation].aos-animate .emb-lineup__tirecontainer:nth-child(5) {
  transition-delay: 0.45s;
}
.emb-lineup[data-aos=my-animation].aos-animate .emb-lineup__bottom {
  transition-delay: 0.6s;
}
html.avon .emb-lineup .lineup__title, html.avon .emb-lineup__bottomheading, html.avon .emb-lineup__bottomlink-title {
  text-transform: uppercase;
}
html.avon .emb-lineup__bottomheading {
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.64px;
}
html.avon .emb-lineup__bottomtext {
  font-size: 20px;
  line-height: 24px;
  color: #A0A0A0;
}
html.avon .emb-lineup .lineupslideshow {
  margin-bottom: 0;
  padding-top: 0;
}
html.avon .emb-lineup .lineupslideshow:before {
  display: none;
}
html.avon .emb-lineup .lineupslideshow .swiper-container {
  display: flex;
  flex-direction: column;
}
html.avon .emb-lineup .lineupslideshow .lineupslideshow__navigation {
  order: 2;
  margin-top: 16px;
}
html.avon .emb-lineup .lineupslideshow .circlearrow {
  border: 2px solid rgba(160, 160, 160, 0.2);
}
html.avon .emb-lineup .lineupslideshow .circlearrow .circlearrow__avonsvg--white {
  display: none;
}
html.avon .emb-lineup .lineupslideshow .tireimageblock {
  box-shadow: none;
}
@media screen and (max-width: 1000px) {
  .emb-lineup__linebreak {
    display: block;
    width: 100%;
  }
  .emb-lineup__category {
    justify-content: center;
  }
  .emb-lineup__tirecontainer {
    width: 300px;
    margin: 0 15px 30px;
  }
}
@media screen and (max-width: 690px) {
  .emb-lineup {
    padding-left: 0;
    padding-right: 0;
  }
  .emb-lineup__categories {
    display: none;
  }
  .emb-lineup__bottom {
    padding-left: 23px;
    padding-right: 23px;
    flex-direction: column;
  }
  .emb-lineup__bottomheading, .emb-lineup__bottomtextcontainer {
    max-width: 100%;
  }
  .emb-lineup__bottomheading {
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 15px;
  }
  .emb-lineup__bottomtextcontainer {
    font-size: 20px;
    line-height: 25px;
  }
  .emb-lineup__bottomlink-title {
    font-size: 16px;
    line-height: 100%;
  }
  .emb-lineup__mobileslideshow {
    display: block;
  }
  .emb-lineup__mobileslideshow .swiper-slide {
    width: 300px;
  }
}