.download {
  width: 100%;
  max-width: 770px;
  margin: 0 auto;
}
.download__title {
  font-size: 36px;
  line-height: 30px;
  margin: 0 0 0.9375rem;
}
.download__block {
  background: #F4F5F7;
  margin: 3.125rem 0 0;
  display: flex;
  text-decoration: none;
}
.download__titlefilename {
  position: relative;
}
.download__info {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 160px 0 65px;
}
html[dir=rtl] .download__info {
  padding-left: 160px;
  padding-right: 65px;
}
.download__downloadicon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 65px;
}
html[dir=rtl] .download__downloadicon {
  left: 65px;
  right: auto;
}
.download__image {
  display: block;
}
.download__downloadtitle {
  font-size: 28px;
  line-height: 30px;
  margin: 0 0 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
}
html.cooper .download__downloadtitle {
  color: #001d60;
}
html.avon .download__downloadtitle {
  color: #fff;
}
.download__filename {
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
}
html.avon .download__downloadicon #Oval-7, html.avon .download__downloadicon #Oval-6 {
  fill: #ED0000;
}
@media (max-width: 576px) {
  .download__image-container, .download__downloadicon {
    display: none;
  }
  .download__block {
    margin-top: 20px;
  }
  .download__info {
    padding: 40px 20px;
  }
}