.relatedarticles {
  padding: 7.5rem 0;
}
.relatedarticles .swiper-button-prev {
  left: -5vw;
  background-image: url("../../web/img/carousel-prev.svg");
}
.relatedarticles .swiper-button-next {
  right: -5vw;
  background-image: url("../../web/img/carousel-next.svg");
}
@media screen and (max-width: 1300px) {
  .relatedarticles .swiper-button-prev {
    left: 10px;
  }
  .relatedarticles .swiper-button-next {
    right: 10px;
  }
}
@media (max-width: 1024px) {
  .relatedarticles .swiper-button-prev {
    left: 5vw;
  }
  .relatedarticles .swiper-button-next {
    right: 5vw;
  }
}
.relatedarticles__mobilenav {
  display: none;
}
@media (max-width: 768px) {
  .relatedarticles .swiper-button-next,
  .relatedarticles .swiper-button-prev {
    display: none;
  }
  html.cooper .relatedarticles__mobilenav, html.avon .relatedarticles__mobilenav {
    display: inline-block;
    background-image: none;
    width: auto;
  }
  html.cooper .relatedarticles__mobilenav--next, html.avon .relatedarticles__mobilenav--next {
    right: 0;
  }
  html.cooper .relatedarticles__mobilenav--prev, html.avon .relatedarticles__mobilenav--prev {
    left: 0;
    transform: scaleX(-1);
  }
}
.relatedarticles .contentwrapper {
  position: relative;
}
.relatedarticles__blocktitle {
  margin-top: 0;
  text-align: center;
}
.relatedarticles .imagetextblock {
  height: 435px;
}
.relatedarticles .imagetextblock__image-container {
  height: 100%;
}
.relatedarticles .swiper-container,
.relatedarticles .swiper-wrapper {
  width: 100%;
}
.relatedarticles .swiper-slide {
  width: 50%;
  margin-right: 12px;
}
html[dir=rtl] .relatedarticles .swiper-slide {
  margin-left: 12px;
  margin-right: 0;
}
html.cooper .relatedarticles__article-title, html.avon .relatedarticles__article-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 30px;
  margin: 1.875rem 0 0.9375rem;
}
html.avon .relatedarticles__article-title {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
html.cooper .relatedarticles__richtext {
  color: #5C666F;
}
html.avon .relatedarticles__richtext {
  color: inherit;
}
.relatedarticles.no-slide .swiper-button-prev,
.relatedarticles.no-slide .swiper-button-next {
  display: none;
}
html.cooper .relatedarticles {
  background: #F4F5F7;
}
html.avon .relatedarticles .imagetextblock__image-container {
  position: relative;
}
html.avon .relatedarticles .imagetextblock__image-container .button {
  position: absolute;
  bottom: -2px;
  right: -30px;
  text-decoration: none;
}
html.avon .relatedarticles .imagetextblock__imagelink {
  position: relative;
  overflow: hidden;
}
@media (max-width: 768px) {
  .relatedarticles {
    padding-top: 7vh;
    padding-bottom: 7vh;
  }
  .relatedarticles__article-title, .relatedarticles__richtext {
    padding-left: 20px;
    padding-right: 20px;
  }
  html.cooper .relatedarticles__article-title {
    margin-top: 3.125rem;
  }
  html.cooper .relatedarticles {
    background: #E5E5E5;
  }
}