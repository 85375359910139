html, body, form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

datalist, template {
  display: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

button {
  border: 0;
  border-radius: 0;
  background: none;
  cursor: pointer;
}

html,
body {
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
}

html.wh-errorpage body {
  overflow-x: visible;
}

html {
  --background-color: #ffffff;
  --footer-background: #F4F5F7;
  --footer-heading-color: #001d60;
  font-size: 16px;
  background: var(--background-color);
}
html.has-body-bg {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
html.avon {
  --background-color: #333333;
  --footer-background: #ffffff;
  --footer-heading-color: #333333;
  --avon-red: #C31E26;
  --font-heading: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-default: Inter, Arial, Helvetica, sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 768px) {
  body {
    padding-top: 100px;
  }
  html.show-cookie-bar body {
    padding-top: 150px;
  }
}

.sitewrapper {
  margin: 0 auto;
  max-width: 100vw;
  z-index: 0;
  position: relative;
}

.contentwrapper, main > .wh-form,
main > .crumblepath,
.pagesidebar {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1170px;
}
html.avon .contentwrapper, html.avon main > .wh-form,
html.avon main > .crumblepath,
html.avon .pagesidebar {
  max-width: 1440px;
}
@media screen and (max-width: 1440px) {
  html.avon .contentwrapper, html.avon main > .wh-form,
  html.avon main > .crumblepath,
  html.avon .pagesidebar {
    padding: 0 13px;
  }
}
@media screen and (max-width: 1170px) {
  .contentwrapper, main > .wh-form,
  main > .crumblepath,
  .pagesidebar {
    padding: 0 13px;
  }
}

.pac-container .pac-item {
  font: 18px/100% "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
html.avon .pac-container .pac-item {
  font: 16px/100% "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}