/* Google Location suggest */
.pac-container .pac-item {
  font: inherit;
  border-top: 0 none;
  padding: 9px 10px 7px;
  cursor: pointer;
}
html.page-tiredetails .pac-container .pac-item {
  padding-left: 70px;
}
@media (max-width: 768px) {
  html.page-tiredetails .pac-container .pac-item {
    padding-left: 8.3333333333vw;
  }
}
.pac-container .pac-item:hover {
  background-color: #eee;
}
.pac-container .pac-icon {
  display: none;
}
.pac-container .pac-item-query {
  font: inherit;
  color: inherit;
}

.p-dealer-locator .p-pagehelper {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
}
.p-dealer-locator .p-pagehelper .pagehelper {
  position: static;
  transform: none;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 90px;
}
.p-dealer-locator .p-dealerresult__footer {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.p-dealer-locator .p-dealerresult__labels {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  color: #b2b2b2;
  font-family: "ITC Avant Garde Gothic W01BkCn";
  margin-top: 12px;
  text-transform: uppercase;
}
.p-dealer-locator .p-dealerresult__labels > li {
  display: inline-block;
  background-color: #f4f5f7;
  margin-left: 20px;
  margin-top: -5px;
  padding: 8px;
}
.p-dealer-locator .p-dealerresult__details {
  display: none;
  margin-top: 20px;
  padding: 20px 40px 20px;
  border-top: 1px solid #e2e2e2;
  margin-left: -100px;
  margin-right: -20px;
}
@media (max-width: 768px) {
  .p-dealer-locator .p-dealerresult--showdetails .p-dealerresult__details {
    display: block;
  }
}
.p-dealer-locator .p-uselocation {
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 2.8125rem;
  position: relative;
}
.p-dealer-locator .p-uselocation a {
  text-decoration: none;
  font-size: 1.125rem;
}
html.cooper .p-dealer-locator .p-uselocation a {
  color: #5C666F;
}
html.avon .p-dealer-locator .p-uselocation a {
  color: inherit;
}
html.non-touch .p-dealer-locator .p-uselocation a:hover {
  text-decoration: underline;
}
.p-dealer-locator .p-uselocation #use-my-location {
  position: relative;
}
.p-dealer-locator .p-uselocation--retrieving-location::after {
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #fff;
  opacity: 0.5;
}
.p-dealer-locator .p-uselocation__marker {
  position: relative;
  top: 1px;
  margin-left: 10px;
}
html[dir=rtl] .p-dealer-locator .p-uselocation__marker {
  margin-right: 10px;
  margin-left: 0;
}
.p-dealer-locator .p-uselocation .fa {
  position: absolute;
  top: 0px;
  right: -30px;
  visibility: hidden;
}
.p-dealer-locator .p-uselocation .fa.show {
  visibility: visible;
}
.p-dealer-locator .p-searchform {
  position: relative;
  margin: 0 auto;
  max-width: 916px;
}
.p-dealer-locator .p-searchform--searching::after {
  content: "";
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #fff;
  opacity: 0.5;
}
.p-dealer-locator .p-searchform__searchcontainer {
  display: flex;
}
.p-dealer-locator .p-searchform #dealersearchinput {
  flex: 1;
  margin-right: 12px;
}
html[dir=rtl] .p-dealer-locator .p-searchform #dealersearchinput {
  margin-left: 12px;
  margin-right: 0;
}
@media (max-width: 768px) {
  .p-dealer-locator .p-searchform__searchcontainer {
    display: block;
  }
  .p-dealer-locator .p-searchform #dealersearchinput {
    margin-bottom: 15px;
  }
}
html.cooper .p-dealer-locator .p-searchbutton, html.avon .p-dealer-locator .p-searchbutton {
  min-width: 220px;
  width: 9.125rem;
  font-size: 1.125rem;
}
html.cooper .p-dealer-locator .p-searchbutton {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.p-dealer-locator .p-categories {
  background: #fff;
  white-space: nowrap;
  padding: 10px 5px 20px 30px;
}
.p-dealer-locator .p-categories__category {
  display: inline-block;
}
.p-dealer-locator .p-categories__label {
  cursor: pointer;
  position: relative;
  top: 3px;
  left: 2px;
}
.p-dealer-locator .p-categories .wh-form__fieldgroup--checkbox {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 30px;
}
.p-dealer-locator .p-categories .wh-form__fieldgroup--checkbox input[type=checkbox] + label {
  width: 22px;
}
.p-dealer-locator .p-categories .wh-form__fieldgroup--checkbox input[type=checkbox] + label + span + label {
  position: static;
}
.p-dealer-locator .p-categories .wh-form__fieldgroup--checkbox input[type=checkbox] + label::before {
  width: 14px;
  height: 14px;
}
.p-dealer-locator .p-categories .wh-form__fieldgroup--checkbox input[type=checkbox] + label::after {
  width: 10px;
  height: 10px;
  top: -10px;
  left: 2px;
}
.p-dealer-locator .p-map-container {
  position: relative;
}
.p-dealer-locator #dealerresults {
  background: #fff;
  width: 370px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 65px;
  left: 0;
}
@media screen and (max-width: 1400px) {
  html.avon .p-dealer-locator #dealerresults {
    top: 15px;
  }
}
html[dir=rtl] .p-dealer-locator #dealerresults {
  left: auto;
  right: 0;
}
.p-dealer-locator .p-dealerresult {
  border-bottom: 1px solid #e2e2e2;
  display: block;
  padding: 32px 20px 20px 100px;
  position: relative;
  text-decoration: none;
  background: #fff;
  transition: background 0.3s;
}
html[dir=rtl] .p-dealer-locator .p-dealerresult {
  padding-left: 20px;
  padding-right: 100px;
}
.p-dealer-locator .p-dealerresult:first-child {
  border-top: 1px solid #e2e2e2;
}
.p-dealer-locator .p-dealerresult:hover {
  background: #F4F5F7;
}
.p-dealer-locator .p-dealerresult:not([data-ambassador=true]) .p-dealerresult__ambassador, .p-dealer-locator .p-dealerresult:not([data-clubcooper=true]) .p-dealerresult__clubcooper {
  display: none;
}
.p-dealer-locator .p-dealerresult[data-ambassador=true], .p-dealer-locator .p-dealerresult[data-clubcooper=true] {
  padding-bottom: 60px;
}
.p-dealer-locator .p-dealerresult .p-dealerresult__ambassador, .p-dealer-locator .p-dealerresult .p-dealerresult__clubcooper {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.p-dealer-locator .p-dealerresult .p-dealerresult__ambassador {
  background: linear-gradient(90deg, #d8b351 0%, #a18544 100%);
}
.p-dealer-locator .p-dealerresult .p-dealerresult__clubcooper {
  background: #000000;
}
.p-dealer-locator .p-dealerresult__logo {
  position: absolute;
  top: 32px;
  left: 21px;
  line-height: 0;
}
.p-dealer-locator .p-dealerresult__logo.custom {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  width: 60px;
}
.p-dealer-locator .p-dealerresult__logo.custom > img {
  vertical-align: bottom;
  width: 100%;
  height: auto;
}
html[dir=rtl] .p-dealer-locator .p-dealerresult__logo {
  left: auto;
  right: 21px;
}
.p-dealer-locator .p-dealerresult__next {
  float: right;
  display: inline-block;
}
html[dir=rtl] .p-dealer-locator .p-dealerresult__next {
  float: left;
  transform: scaleX(-1);
}
@media (max-width: 768px) {
  .p-dealer-locator .p-dealerresult__next {
    transform: rotate(90deg);
    height: 25px;
  }
  .p-dealer-locator .p-dealerresult--showdetails .p-dealerresult__next {
    transform: rotate(-90deg);
  }
}
html.cooper .p-dealer-locator .p-dealerresult--selected {
  background: #F4F5F7;
}
html.cooper .p-dealer-locator .p-dealerresult__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 100%;
  color: #001d60;
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
}
html.cooper html.cooper .p-dealer-locator .p-dealerresult__title {
  color: #001d60;
}
html.avon html.cooper .p-dealer-locator .p-dealerresult__title {
  color: #fff;
}
html.cooper .p-dealer-locator .p-dealerresult__address {
  font-size: 18px;
  line-height: 18px;
  color: #5C666F;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin-top: 10px;
  padding-right: 25px;
}
html.cooper .p-dealer-locator .p-dealerresult__distance {
  font-size: 14px;
  line-height: 20px;
  color: #b2b2b2;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 12px;
}
html.cooper .p-dealer-locator .p-dealerresult__distance img {
  position: relative;
  top: 3px;
}
html.cooper .p-dealer-locator .p-dealerresult__distance-distance {
  margin-left: 10px;
  text-transform: uppercase;
}
html.cooper .p-dealer-locator .p-dealerresult__premium {
  font-size: 18px;
  line-height: 40px;
  background: #001d60;
  bottom: 0;
  color: #fff;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  height: 40px;
  left: 0;
  padding: 0 20px 0 100px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  width: 100%;
}
html.cooper .p-dealer-locator .p-dealerresult__premium-stars {
  padding-left: 25px;
}
html.cooper[dir=rtl] .p-dealer-locator .p-dealerresult__address, html.avon[dir=rtl] .p-dealer-locator .p-dealerresult__address {
  padding-right: 0;
  padding-left: 25px;
}
html.cooper[dir=rtl] .p-dealer-locator .p-dealerresult__distance-distance, html.avon[dir=rtl] .p-dealer-locator .p-dealerresult__distance-distance {
  margin-right: 10px;
  margin-left: 0;
}
html.cooper[dir=rtl] .p-dealer-locator .p-dealerresult__premium, html.avon[dir=rtl] .p-dealer-locator .p-dealerresult__premium {
  padding-left: 20px;
  padding-right: 100px;
}
html.cooper[dir=rtl] .p-dealer-locator .p-dealerresult__premium-stars, html.avon[dir=rtl] .p-dealer-locator .p-dealerresult__premium-stars {
  padding-right: 25px;
  padding-left: 0;
}
html.avon .p-dealer-locator .p-dealerresult--selected {
  background: #F4F5F7;
}
html.avon .p-dealer-locator .p-dealerresult__title {
  font-family: "ITC Avant Garde Gothic W01BkCn";
  font-size: 18px;
  line-height: 100%;
  color: #fff;
  font-weight: bold;
}
html.cooper html.avon .p-dealer-locator .p-dealerresult__title {
  color: #001d60;
}
html.avon html.avon .p-dealer-locator .p-dealerresult__title {
  color: #fff;
}
html.avon .p-dealer-locator .p-dealerresult--selected .svg-dealer-info-next, html.avon .p-dealer-locator .p-dealerresult:hover .svg-dealer-info-next {
  stroke: #ED0000;
}
html.avon .p-dealer-locator .p-dealerresult__address {
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  font-family: "ITC Avant Garde Gothic W01BkCn";
  font-weight: normal;
  margin-top: 10px;
  padding-right: 25px;
}
html.avon .p-dealer-locator .p-dealerresult__distance {
  font-size: 14px;
  line-height: 20px;
  color: #b2b2b2;
  font-family: "ITC Avant Garde Gothic W01BkCn";
  margin-top: 12px;
}
html.avon .p-dealer-locator .p-dealerresult__distance img {
  position: relative;
  top: 3px;
}
html.avon .p-dealer-locator .p-dealerresult__distance-distance {
  margin-left: 10px;
  text-transform: uppercase;
}
html.avon .p-dealer-locator .p-dealerresult__premium {
  font-size: 18px;
  line-height: 40px;
  background: #fff;
  bottom: 0;
  color: #fff;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  height: 40px;
  left: 0;
  padding: 0 20px 0 100px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  width: 100%;
}
html.avon .p-dealer-locator .p-dealerresult__premium-stars {
  padding-left: 25px;
}
.p-dealer-locator .p-dealerresults-nav {
  font-size: 18px;
  line-height: 75px;
  color: #5C666F;
  font-weight: bold;
  height: 75px;
  display: flex;
}
html.cooper .p-dealer-locator .p-dealerresults-nav {
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.avon .p-dealer-locator .p-dealerresults-nav {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.p-dealer-locator .p-dealerresults-newsearch {
  color: #001d60;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px;
}
html.cooper .p-dealer-locator .p-dealerresults-newsearch {
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.p-dealer-locator .p-dealerresults-newsearch .icon {
  display: inline-block;
  position: relative;
  top: 2px;
  padding-left: 8px;
}
html.avon .p-dealer-locator .p-dealerresults-newsearch {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #fff;
}
html.avon .p-dealer-locator .p-dealerresults-newsearch .icon g {
  fill: #000000;
}
.p-dealer-locator .p-dealerresults-prev,
.p-dealer-locator .p-dealerresults-next {
  flex: 0 0 60px;
  height: 75px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.1;
  pointer-events: none;
  cursor: default;
}
.p-dealer-locator .p-dealerresults-prev.show,
.p-dealer-locator .p-dealerresults-next.show {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
html[dir=rtl] .p-dealer-locator .p-dealerresults-prev,
html[dir=rtl] .p-dealer-locator .p-dealerresults-next {
  transform: scaleX(-1);
}
.p-dealer-locator .p-dealerresults-count {
  flex: 1;
  text-align: center;
}
.p-dealer-locator #google-maps-container {
  width: 100vw;
  height: 85vh;
  min-height: 870px;
}
html.avon .p-dealer-locator #google-maps-container {
  min-height: 857px;
}
.p-dealer-locator .p-dealerinfo {
  background: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.16);
  margin-left: 50px;
  margin-top: -125px;
  padding: 25px 25px 40px;
  position: absolute;
  width: 300px;
  /*
      &__openinghours
      {
        table
        {
          border-collapse: collapse;
          margin: 0 40px 0 0;
          padding: 0;
        }
        th, td
        {
          text-align: left;
          vertical-align: top;
          white-space: nowrap;
          text-transform: uppercase;
          width: 50%;
          font-size: 14px;
          line-height: 21px;
          font-family: "Trade Gothic LT", Arial;
        }

        th
        {
          font-weight: normal;
        }

        td
        {
          font-weight: bold;
        }

        .hours
        {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-end;

          & > span
          {
            display: inline-block;
            position: relative;
            flex: 1 1 45%;
            max-width: 45%;
            text-align: right;

            &.separator
            {
              flex: 0 0 10%;
              max-width: 10%;
            }
          }
        }
      }
  */
}
html[dir=rtl] .p-dealer-locator .p-dealerinfo {
  margin-left: auto;
  margin-right: 50px;
}
@media (max-width: 768px) {
  .p-dealer-locator .p-dealerinfo {
    display: none;
  }
}
.p-dealer-locator .p-dealerinfo__image {
  margin-bottom: 10px;
  line-height: 0;
}
.p-dealer-locator .p-dealerinfo__image img {
  max-width: 210px;
  height: auto;
  vertical-align: bottom;
  /*
  width: 60px;
  height: 60px;
  */
  vertical-align: bottom;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}
.p-dealer-locator .p-dealerinfo__openinghours {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  text-transform: uppercase;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-bottom: 5px;
  font-size: 13px;
  align-items: flex-start;
}
.p-dealer-locator .p-dealerinfo__openinghours .title {
  background: #001d60;
  color: #fff;
  padding: 5px 10px;
}
html.avon .p-dealer-locator .p-dealerinfo__openinghours .title {
  background-color: #000;
}
.p-dealer-locator .p-dealerinfo__openinghours .icon {
  color: #ED0000;
}
.p-dealer-locator .p-dealerinfo__openinghours .hours__line {
  padding-top: 4px;
}
.p-dealer-locator .p-dealerinfo__openinghours .hours__line > span {
  display: inline-block;
  padding-left: 5px;
}
.p-dealer-locator .p-dealerinfo__close {
  float: right;
}
html[dir=rtl] .p-dealer-locator .p-dealerinfo__close {
  float: left;
}
.p-dealer-locator .p-dealerinfo__tip {
  height: 60px;
  left: -35px;
  overflow: hidden;
  position: absolute;
  top: 75px;
  width: 35px;
}
html[dir=rtl] .p-dealer-locator .p-dealerinfo__tip {
  left: auto;
  right: -35px;
}
.p-dealer-locator .p-dealerinfo__tip::after {
  background: #fff;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.16);
  content: "";
  display: block;
  height: 40px;
  left: 15px;
  position: relative;
  top: 10px;
  transform: rotate(45deg);
  width: 40px;
}
html[dir=rtl] .p-dealer-locator .p-dealerinfo__tip::after {
  right: 15px;
  left: auto;
}
.p-dealer-locator .p-dealerinfo__title {
  font-size: 24px;
  line-height: 100%;
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding-right: 28px;
}
html.cooper .p-dealer-locator .p-dealerinfo__title {
  color: #001d60;
}
html.avon .p-dealer-locator .p-dealerinfo__title {
  color: #fff;
}
html[dir=rtl] .p-dealer-locator .p-dealerinfo__title {
  padding-left: 28px;
  padding-right: 0;
}
.p-dealer-locator .p-dealerinfo__address {
  font-size: 18px;
  line-height: 20px;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin-top: 10px;
}
html.cooper .p-dealer-locator .p-dealerinfo__address {
  color: #5C666F;
}
html.avon .p-dealer-locator .p-dealerinfo__address {
  color: inherit;
}
.p-dealer-locator .p-dealerinfo__distance {
  font-size: 14px;
  line-height: 20px;
  color: #b2b2b2;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 10px;
}
.p-dealer-locator .p-dealerinfo__distance img {
  position: relative;
  top: 3px;
}
.p-dealer-locator .p-dealerinfo__distance-distance {
  margin-left: 10px;
  text-transform: uppercase;
}
html[dir=rtl] .p-dealer-locator .p-dealerinfo__distance-distance {
  margin-left: 0;
  margin-right: 10px;
}
.p-dealer-locator .p-dealerinfo__premium {
  font-size: 18px;
  line-height: 40px;
  background: #001d60;
  color: #fff;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  height: 40px;
  margin-top: 15px;
  padding-left: 20px;
  text-transform: uppercase;
  width: 100%;
}
html[dir=rtl] .p-dealer-locator .p-dealerinfo__premium {
  padding-right: 20px;
  padding-left: 0;
}
.p-dealer-locator .p-dealerinfo__premium-stars {
  padding-left: 15px;
}
html[dir=rtl] .p-dealer-locator .p-dealerinfo__premium-stars {
  padding-right: 15px;
  padding-left: 0;
}
.p-dealer-locator .p-dealerinfo__divider {
  background-color: #e2e2e2;
  height: 1px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: calc(100% + 25px);
}
.p-dealer-locator .p-dealerinfo__divider + .p-dealerinfo__divider {
  display: none;
}
.p-dealer-locator .p-dealerinfo__info-title {
  font-size: 14px;
  line-height: 100%;
  color: #5C666F;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 15px;
  text-transform: uppercase;
}
.p-dealer-locator .p-dealerinfo__info-value {
  font-size: 18px;
  line-height: 100%;
  color: #001d60;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-decoration: none;
  margin-top: 7px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.p-dealer-locator .p-dealerinfo__website {
  background-color: #fff;
  border: 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.22);
  color: #001d60;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 8px;
  min-width: 0;
  width: 100%;
}
.p-dealer-locator .p-dealerinfo__buttons {
  margin-top: 25px;
}
@media (max-width: 768px) {
  .p-dealer-locator .p-dealerinfo {
    margin-left: -124px;
    margin-top: -175px;
  }
  html[dir=rtl] .p-dealer-locator .p-dealerinfo {
    margin-left: auto;
    margin-right: -124px;
  }
  .p-dealer-locator .p-dealerinfo .p-dealerinfo__tip {
    display: none;
  }
}
html.avon .p-dealer-locator .p-dealerinfo__title, html.avon .p-dealer-locator .p-dealerinfo__address, html.avon .p-dealer-locator .p-dealerinfo__distance, html.avon .p-dealer-locator .p-dealerinfo__info, html.avon .p-dealer-locator .p-dealerinfo__info-title, html.avon .p-dealer-locator .p-dealerinfo__info-value {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.avon .p-dealer-locator .p-dealerinfo__title {
  font-size: 18px;
  line-height: 100%;
  font-weight: bold;
}
html.avon .p-dealer-locator .p-dealerinfo__address {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
html.avon .p-dealer-locator .p-dealerinfo__premium {
  background: #fff;
}
html.avon .p-dealer-locator .p-dealerinfo__website, html.avon .p-dealer-locator .p-dealerinfo__info-title {
  color: inherit;
}
html.avon .p-dealer-locator .p-dealerinfo__website {
  background-color: #fff;
}
html.avon .p-dealer-locator .p-dealerinfo .svg-dealer-info-close {
  stroke: #ED0000;
}
html.avon .p-dealer-locator .p-dealerinfo__button {
  transform: skew(0);
  max-width: 100%;
}
html.avon .p-dealer-locator .p-dealerinfo__button > .button__title {
  transform: skew(0);
  min-width: 0;
}
html.avon .p-dealer-locator .p-dealerinfo__info-value {
  color: #ED0000;
}
html.cooper .p-dealer-locator .p-dealerinfo__button, html.avon .p-dealer-locator .p-dealerinfo__button {
  min-width: 0;
  width: 100%;
  border: 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.22);
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin-top: 8px;
}
html.avon .p-dealer-locator .p-pagehelper .pagehelper {
  padding-top: 0;
}
html.avon .p-dealer-locator .p-uselocation a {
  text-transform: none;
  color: #9b9b9b;
}
html.avon .p-dealer-locator .p-searchform .wh-form__label {
  text-transform: none;
}
html.avon .p-dealer-locator .p-searchform :-moz-placeholder {
  text-transform: none;
}
html.avon .p-dealer-locator .p-searchform ::-moz-placeholder {
  text-transform: none;
}
html.avon .p-dealer-locator .p-searchform :-ms-input-placeholder {
  text-transform: none;
}
html.avon .p-dealer-locator .p-searchform ::-webkit-input-placeholder {
  text-transform: none;
}
html.avon .p-dealer-locator .p-searchform .button {
  transform: skew(0);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
  justify-content: center;
  text-transform: uppercase;
}
html.avon .p-dealer-locator .p-searchform .button__title {
  font-weight: bold;
  transform: skew(0);
  min-width: 0;
}
@media (max-width: 768px) {
  .p-dealer-locator .p-pagehelper {
    height: calc(100vh - 100px);
  }
  .p-dealer-locator #google-maps-container {
    min-height: 0;
    height: 70vh;
  }
  html.avon .p-dealer-locator #google-maps-container {
    min-height: 0;
  }
  .p-dealer-locator #dealerresults {
    position: static;
    width: 100%;
    box-shadow: none;
  }
  .p-dealer-locator .p-searchform .wh-form__fieldgroup {
    text-align: center;
  }
  html.cooper .p-dealer-locator .p-searchform .p-searchbutton, html.avon .p-dealer-locator .p-searchform .p-searchbutton {
    width: 100%;
  }
}

html.avon.page-dealer-locator .p-dealer-locator {
  margin-top: 100px;
}