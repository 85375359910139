.mobilemenu {
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: #fff;
  z-index: 4000;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 0;
  transition: height 0.5s;
  will-change: height;
}
.mobilemenu .p-cars {
  background: #F4F5F7;
  padding: 20px 0 0;
}
.mobilemenu .p-cars .contentwrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 8.3333333333vw;
}
.mobilemenu .p-cars__link {
  display: flex;
  flex-direction: column;
  flex: 0 0 19%;
  text-decoration: none;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 2px solid transparent;
}
.mobilemenu .p-cars__link:hover, .mobilemenu .p-cars__link.selected {
  border-bottom-color: #EB0328;
}
.mobilemenu .p-cars__link:hover .p-cars__title, .mobilemenu .p-cars__link.selected .p-cars__title {
  opacity: 1;
  color: #EB0328;
}
.mobilemenu .p-cars__image {
  width: 100%;
  display: block;
}
html[dir=rtl] .mobilemenu .p-cars__image {
  transform: scaleX(-1);
}
.mobilemenu .p-cars__title {
  font-size: 12px;
  line-height: 100%;
  align-self: center;
  font-weight: 700;
  opacity: 0.2;
  text-transform: uppercase;
  margin: 12px 0 4px;
}
html.cooper .mobilemenu .p-cars__title {
  color: #001d60;
}
html.avon .mobilemenu .p-cars__title {
  color: #fff;
}
html.avon .mobilemenu .p-cars__title {
  font-size: 10px;
  line-height: 100%;
}
html.avon .mobilemenu .p-cars__image--motorcycle {
  width: 70%;
}
html.avon .mobilemenu .p-cars__image--motorsport {
  width: 100%;
}
.mobilemenu .p-links {
  padding: 20px 8.3333333333vw 0;
}
.mobilemenu .p-links__link {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
}
html.cooper .mobilemenu .p-links__link {
  color: #001d60;
}
html.avon .mobilemenu .p-links__link {
  color: #fff;
}
.mobilemenu .p-links__link--big::after, .mobilemenu .p-links__link--smaller::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 19px;
  background: url("../web/img/gt_red.svg") no-repeat;
  background-size: 100%;
}
html[dir=rtl] .mobilemenu .p-links__link--big::after, html[dir=rtl] .mobilemenu .p-links__link--smaller::after {
  right: auto;
  left: 0;
  transform: translateY(-50%) scaleX(-1);
}
.mobilemenu .p-links__link--big {
  font-size: 36px;
  line-height: 100%;
  font-weight: 700;
}
.mobilemenu .p-links__link--smaller {
  font-size: 24px;
  line-height: 100%;
  margin: 20px 0 0;
}
.mobilemenu .p-links__link--smaller::after {
  top: 3px;
  transform: none;
  transition: transform 0.3s;
}
.mobilemenu .p-links__link--smallest {
  font-size: 16px;
  line-height: 100%;
  margin: 20px 0;
}
.mobilemenu .p-links__smaller {
  margin-top: 35px;
}
.mobilemenu .p-links__items--big {
  margin: 25px 0;
}
.mobilemenu .p-links__line {
  background-color: #001d60;
  height: 2px;
  width: 100%;
  margin: 40px 0;
}
.mobilemenu .p-links__subitems {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}
.mobilemenu .p-links__items.expanded .p-links__subitems {
  max-height: 40vh;
}
.mobilemenu .p-links__items.expanded .p-links__link::after {
  transform: rotate(90deg);
}

html.menu-open body {
  overflow: hidden;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
}
html.menu-open .mobilemenu {
  height: calc(100vh - 100px);
  padding: 0 0 10vh;
}