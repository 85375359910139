.twoimages {
  margin: 30px 0;
}
.twoimages .contentwrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.twoimages__container {
  width: calc(50% - 12px);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 605px;
}
html.cooper .twoimages {
  padding-bottom: 2.2rem;
}
html.avon .twoimages .imagetextblock__textcontainer > * {
  position: relative;
}
html.avon .twoimages .imagetextblock__textcontainer:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 50%;
  background-image: url(../../web/img/avon-pattern40-i.png);
  background-position: 0 0;
  background-size: auto 100px;
  background-repeat: repeat-x;
}
html.avon .twoimages__container {
  overflow: hidden;
}
@media (max-width: 768px) {
  .twoimages .contentwrapper {
    display: block;
  }
  .twoimages__container {
    width: 100%;
    margin-bottom: calc(20px + 4.4rem / 2);
  }
  .twoimages__container--hideonmobile {
    display: none;
  }
}