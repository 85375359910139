.emb-sponsorship2__categories {
  margin-bottom: 70px;
}
.emb-sponsorship2__category {
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-left: -20px;
  display: none;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  justify-content: center;
}
.emb-sponsorship2__category .emb-lineup__tirecontainer {
  flex: 1 0 300px;
  margin-top: 20px;
  margin-left: 20px;
}
@media (min-width: 640px) {
  .emb-sponsorship2__category .emb-lineup__tirecontainer {
    max-width: calc(50% - 20px);
  }
}
@media (min-width: 960px) {
  .emb-sponsorship2__category .emb-lineup__tirecontainer {
    max-width: calc(33.3333333333% - 20px);
  }
}
@media (min-width: 1280px) {
  .emb-sponsorship2__category .emb-lineup__tirecontainer {
    max-width: calc(25% - 20px);
  }
}
@media (min-width: 1200px) {
  .emb-sponsorship2__category .emb-lineup__tirecontainer {
    min-width: calc(25% - 20px);
  }
}
.emb-sponsorship2__category.enabled {
  display: flex;
}
html.avon .emb-sponsorship2__category {
  max-width: 1440px;
}
@media screen and (max-width: 1440pxpx) {
  html.avon .emb-sponsorship2__category {
    padding-right: 25px;
  }
}
@media screen and (max-width: 1280px) {
  .emb-sponsorship2__category {
    padding-right: 25px;
  }
}
.emb-sponsorship2__wrapper {
  display: flex;
  height: 500px;
  padding: 48px 0;
  position: relative;
}
.emb-sponsorship2__navcontainer {
  width: 164px;
  padding: 40px 0 0;
  position: relative;
}
.emb-sponsorship2__navcontainer:before {
  content: "";
  position: absolute;
  top: -40px;
  bottom: -40px;
  right: -130px;
  width: 100vw;
  z-index: 0;
}
html.cooper .emb-sponsorship2__navcontainer:before {
  background-color: #001d60;
}
html.avon .emb-sponsorship2__navcontainer:before {
  background-color: #fff;
}
html[dir=rtl] .emb-sponsorship2__navcontainer:before {
  left: -130px;
  right: auto;
}
.emb-sponsorship2__cardscontainer {
  flex: 1;
  position: relative;
}
.emb-sponsorship2__cards {
  width: 100%;
  display: none;
}
.emb-sponsorship2__cards.enabled {
  display: flex;
}
.emb-sponsorship2__card {
  position: absolute;
  top: 0;
  left: 0;
}
html[dir=rtl] .emb-sponsorship2__card {
  left: auto;
  right: 0;
}
.emb-sponsorship2 .swiper-button-next,
.emb-sponsorship2 .swiper-button-prev {
  position: static;
  top: 0;
  margin-top: 0;
  background: none;
  width: auto;
  height: auto;
  transition: opacity 0.3s;
}
.emb-sponsorship2 .swiper-button-next .circlearrow,
.emb-sponsorship2 .swiper-button-prev .circlearrow {
  display: block;
}
.emb-sponsorship2 .swiper-button-next.swiper-button-disabled,
.emb-sponsorship2 .swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.emb-sponsorship2 .swiper-button-next {
  margin-bottom: 16px;
}
.emb-sponsorship2__navbuttons .swiper-button-prev .circlearrow {
  transform: scaleX(-1);
}
html[dir=rtl] .emb-sponsorship2__navbuttons .swiper-button-prev .circlearrow {
  transform: none;
}
.emb-sponsorship2__mobileslideshow {
  display: none;
}
.emb-sponsorship2__pagenumcontainer {
  margin: 50px 0 34px;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100px;
}
.emb-sponsorship2__pagenum {
  font-size: 100px;
  line-height: 100px;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}
html.cooper .emb-sponsorship2__pagenum {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .emb-sponsorship2__pagenum {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
html[dir=rtl] .emb-sponsorship2__pagenum {
  left: auto;
  right: 0;
}
.emb-sponsorship2__totalnum {
  font-size: 30px;
  line-height: 100%;
  color: #5E73A3;
  position: relative;
  bottom: 10px;
  left: 100px;
}
html.cooper .emb-sponsorship2__totalnum {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .emb-sponsorship2__totalnum {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
html[dir=rtl] .emb-sponsorship2__totalnum {
  left: auto;
  right: 100px;
}
.emb-sponsorship2__progressbarcontainer {
  position: relative;
  width: 205px;
  height: 2px;
  background: #fff;
}
.emb-sponsorship2__progressbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: 20%;
  background: #EB0328;
  transition: width 0.3s;
}
html[dir=rtl] .emb-sponsorship2__progressbar {
  left: auto;
  right: 0;
}
.emb-sponsorship2 .e-card {
  height: 400px;
  padding: 12px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emb-sponsorship2 .e-card__image {
  display: block;
  width: 300px;
  height: 260px;
  margin: 0 0 40px;
}
.emb-sponsorship2 .e-card__logo {
  display: block;
  height: 56px;
}
html.avon .emb-sponsorship2__pagenumcontainer {
  height: 80px;
}
html.avon .emb-sponsorship2__pagenum {
  font-size: 80px;
  line-height: 80px;
}
html.avon .emb-sponsorship2__totalnum {
  font-size: 24px;
  line-height: 100%;
  left: 110px;
  color: #414141;
}
html.avon[dir=rtl] .emb-sponsorship2__totalnum {
  right: 100px;
  left: auto;
}
@media screen and (max-width: 690px) {
  .emb-sponsorship2__wrapper {
    display: none;
  }
  .emb-sponsorship2__mobileslideshow {
    display: block;
  }
  .emb-sponsorship2__mobileslideshow .swiper-container {
    overflow: visible;
  }
  .emb-sponsorship2__mobileslideshow .swiper-slide {
    width: 324px;
    height: 400px;
  }
}