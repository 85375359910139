.cookiebar, .koekjesbalk {
  font-size: 14px;
  line-height: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #F4F5F7;
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease 0.2s;
  z-index: 6000;
}
html.cooper .cookiebar, html.cooper .koekjesbalk {
  color: #001d60;
}
html.avon .cookiebar, html.avon .koekjesbalk {
  color: #fff;
}
html.show-cookie-bar .cookiebar, html.show-cookie-bar .koekjesbalk {
  height: 50px;
}
.cookiebar .contentwrapper, .koekjesbalk .contentwrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  position: relative;
}
.cookiebar__close, .koekjesbalk__close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.cookiebar__close .svg-modal-close, .koekjesbalk__close .svg-modal-close {
  stroke: #001d60;
}
.cookiebar__close svg, .koekjesbalk__close svg {
  width: 22px;
  height: 22px;
  display: block;
}
@media screen and (max-width: 1170px) {
  .cookiebar__close, .koekjesbalk__close {
    right: 13px;
  }
}
@media (max-width: 1024px) {
  .cookiebar__close, .koekjesbalk__close {
    right: 25px;
  }
}
@media (max-width: 768px) {
  .cookiebar__close, .koekjesbalk__close {
    right: 8.3333333333vw;
  }
}
.cookiebar__readmore, .koekjesbalk__readmore {
  text-decoration: none;
  font-weight: 700;
}
html.non-touch .cookiebar__readmore:hover, html.non-touch .koekjesbalk__readmore:hover {
  text-decoration: underline;
}
html.cooper .cookiebar__readmore, html.cooper .koekjesbalk__readmore {
  color: #001d60;
}
html.avon .cookiebar__readmore, html.avon .koekjesbalk__readmore {
  color: #fff;
}
html[dir=rtl] .cookiebar__close, html[dir=rtl] .koekjesbalk__close {
  right: auto;
  left: 0;
}
@media (max-width: 768px) {
  .cookiebar, .koekjesbalk {
    display: none;
  }
  html.show-cookie-bar .cookiebar, html.show-cookie-bar .koekjesbalk {
    height: 110px;
  }
  .mobileheader .cookiebar, .mobileheader .koekjesbalk {
    display: block;
    transition: none;
  }
  .mobileheader .cookiebar .contentwrapper, .mobileheader .koekjesbalk .contentwrapper {
    padding-left: 68px;
    padding-right: 68px;
  }
}

html.show-cookie-bar .header__bottom-bar {
  top: 50px !important;
}

html.show-cookie-bar .header .c-search {
  top: 150px;
}

html.show-cookie-bar .mobileheader {
  top: 110px;
}

html.show-cookie-bar .mobilemenu {
  top: 210px;
}