.widget-socialmedia2 {
  margin-top: clamp(32px, 6dvw, 60px);
  margin-bottom: clamp(32px, 6dvw, 60px);
}
.widget-socialmedia2 ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-socialmedia2__items {
  width: 100%;
  display: grid;
  column-gap: 0;
  grid-row-gap: 24px;
  grid-template-columns: [left-start] 1fr [left-end] 24px [right-start] 1fr [right-end];
}
@media (max-width: 700px) {
  .widget-socialmedia2__items {
    display: block;
  }
}
@media (max-width: 700px) {
  .widget-socialmedia2__item {
    margin-bottom: clamp(16px, 2.4dvw, 24px);
  }
}
.widget-socialmedia2__item:first-child {
  grid-area: 1/left-start/3/left-end;
}
.widget-socialmedia2__item + .widget-socialmedia2__item {
  grid-area: 1/right-start/2/right-end;
}
.widget-socialmedia2__item + .widget-socialmedia2__item + .widget-socialmedia2__item {
  grid-area: 2/right-start/3/right-end;
}
.widget-socialmedia2__item__link {
  height: 100%;
  display: grid;
  column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: 1fr;
  text-decoration: none;
  overflow: hidden;
}
.widget-socialmedia2__item__image {
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  vertical-align: bottom;
  grid-area: 1/1;
  transition: transform 0.3s;
}
.widget-socialmedia2__item__link:hover .widget-socialmedia2__item__image {
  transform: scale(1.1);
}
.widget-socialmedia2__item__overlay {
  position: relative;
  grid-area: 1/1;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 75%, #000 100%);
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: clamp(24px, 4dvw, 40px);
  padding-right: 25%;
}
.widget-socialmedia2__item:first-child .widget-socialmedia2__item__overlay {
  padding-top: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000 100%);
}
.widget-socialmedia2__item__title {
  font-family: var(--font-heading);
  text-transform: uppercase;
  font-size: clamp(32px, 5.6dvw, 56px);
  line-height: 100%;
  margin-bottom: clamp(16px, 2.4dvw, 24px);
}
.widget-socialmedia2__items--count2 .widget-socialmedia2__item:first-child {
  grid-area: 1/left-start/2/left-end;
}
.widget-socialmedia2__items--count2 .widget-socialmedia2__item__overlay {
  padding-right: clamp(24px, 4dvw, 40px);
  padding-top: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000 100%);
}
.widget-socialmedia2__networks {
  margin-top: clamp(24px, 6dvw, 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget-socialmedia2__networks h2 {
  flex: none;
  margin: 0;
  margin-right: clamp(1px, 15dvw, 150px);
}
@media (max-width: 700px) {
  .widget-socialmedia2__networks {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .widget-socialmedia2__networks h2 {
    margin-right: 16px;
  }
}
.widget-socialmedia2__networks__items {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.widget-socialmedia2__networks__item + .widget-socialmedia2__networks__item {
  margin-left: clamp(16px, 2.4dvw, 24px);
}
.widget-socialmedia2__networks__item {
  flex: none;
}
.widget-socialmedia2__networks__item__link {
  text-decoration: none;
  display: flex;
  width: clamp(40px, 6.4dvw, 64px);
  height: clamp(40px, 6.4dvw, 64px);
  align-items: center;
  justify-content: center;
}
html.avon .widget-socialmedia2__networks__item__link {
  background-color: var(--avon-red);
}
.widget-socialmedia2__networks__item svg {
  height: clamp(20px, 3.2dvw, 32px);
  width: auto;
}
html.avon .widget-socialmedia2__networks__item svg .svg-youtube2__arrow {
  fill: var(--avon-red);
}
.widget-socialmedia2__networks__item svg > g, .widget-socialmedia2__networks__item svg .svg-youtube2 {
  fill: #fff;
}