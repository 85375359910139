.findoverlay {
  display: block !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: #F4F5F7;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  min-height: 716px;
  padding: 175px 0 0;
  z-index: 200;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
html.avon .findoverlay {
  color: #000;
}
.findoverlay__close {
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 0;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transform: translateX(-50%);
  transition: opacity 0.2s;
  opacity: 0.6;
}
.findoverlay__close:hover {
  opacity: 1;
}
.findoverlay__back {
  display: none;
  cursor: pointer;
  color: #CACBCC;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "ITC Avant Garde Gothic W01BkCn", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}
.findoverlay__back .angle {
  display: inline-block;
  position: relative;
  top: -1px;
  padding-right: 5px;
}
html.findoverlay-avon-enabled .findoverlay__back {
  display: inline-block;
}
.findoverlay.show {
  opacity: 1;
  pointer-events: auto;
}
.findoverlay__gridcontainer {
  margin: 0 auto;
  width: 100%;
  max-width: 1104px;
  display: flex;
}
@media (max-width: 1200px) {
  .findoverlay__gridcontainer {
    padding-left: 13px;
    padding-right: 13px;
  }
}
.findoverlay__grid {
  width: 100%;
}
.findoverlay__gridleft, .findoverlay__gridright {
  transition: width 0.3s;
}
.findoverlay__gridleft {
  flex: 1;
  padding: 0 24px 130px 0;
  border-right: 0.5px solid #CDCDCD;
}
html[dir=rtl] .findoverlay__gridleft {
  padding-left: 24px;
  padding-right: 0;
  border-left: 0.5px solid #CDCDCD;
  border-right: 0;
}
.findoverlay__grid, .findoverlay__gridleft {
  padding-top: 25px;
}
.findoverlay__gridright {
  width: 258px;
}
.findoverlay__tabs {
  display: flex;
  margin: 0 0 80px;
}
.findoverlay__tab, .findoverlay__tabdivider {
  margin-right: 28px;
}
html[dir=rtl] .findoverlay__tab, html[dir=rtl] .findoverlay__tabdivider {
  margin-left: 28px;
  margin-right: 0;
}
.findoverlay__tab {
  font-size: 24px;
  line-height: 100%;
  position: relative;
  text-decoration: none;
  flex: 1 0 0;
  transition: color 0.3s;
  position: relative;
}
html.cooper .findoverlay__tab {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .findoverlay__tab {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
.findoverlay__tab:after {
  content: "";
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 0;
  height: 3px;
  display: block;
  background: #EB0328;
  transition: width 0.3s;
}
html.non-touch .findoverlay__tab:hover {
  cursor: pointer;
}
html.non-touch .findoverlay__tab:hover:after {
  width: 100%;
}
html.cooper .findoverlay__tab.selected, html.cooper .findoverlay__tab:hover {
  color: #001d60;
}
html.avon .findoverlay__tab.selected, html.avon .findoverlay__tab:hover {
  color: #fff;
}
.findoverlay__tab.selected:after {
  width: 100%;
}
.findoverlay__tabcontainer {
  display: none;
}
.findoverlay__tabcontainer.enabled {
  display: block;
}
.findoverlay__ql-container {
  position: relative;
  padding-left: 70px;
}
html[dir=rtl] .findoverlay__ql-container {
  padding-right: 70px;
  padding-left: 0;
}
.findoverlay__ql-icon, .findoverlay__ql-title {
  transition: top 0.3s, transform 0.3s, opacity 0.3s;
}
.findoverlay__ql-title {
  margin: 30px 0 46px;
  position: absolute;
  top: 0;
  pointer-events: none;
}
.findoverlay__ql-title a {
  text-decoration: none;
  font-size: 24px;
  line-height: 100%;
  color: inherit;
  white-space: nowrap;
}
html.non-touch .findoverlay__ql-title a:hover {
  text-decoration: underline;
}
html.cooper .findoverlay__ql-title a {
  color: #001d60;
}
html.avon .findoverlay__ql-title a {
  color: #fff;
}
html.cooper .findoverlay__ql-title a {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .findoverlay__ql-title a {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
.findoverlay__ql-links {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  transition: opacity 0.3s;
}
.findoverlay__ql-link {
  text-decoration: none;
  font-size: 18px;
  line-height: 100%;
  text-transform: uppercase;
  margin: 0 0 35px;
}
html.non-touch .findoverlay__ql-link:hover {
  text-decoration: underline;
}
.findoverlay__ql-icon {
  position: absolute;
  top: 137px;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
html[dir=rtl] .findoverlay__ql-icon {
  left: auto;
  right: 0;
  transform: scaleX(-1);
}
.findoverlay__locationmarker {
  position: absolute;
  top: -2px;
  left: calc(100% + 13px);
}
.findoverlay__locationmarker #findoverlay-use-my-location-loading {
  display: none;
}
html[dir=rtl] .findoverlay__locationmarker {
  right: calc(100% + 13px);
  left: auto;
}
.findoverlay__tablabel--mobile {
  display: none;
}
.findoverlay__tabdivider {
  font-size: 18px;
  line-height: 24px;
  color: #EB0328;
}
.findoverlay__form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.findoverlay__inputs {
  flex: 1 0 300px;
}
@media (max-width: 700px) {
  .findoverlay__inputs {
    flex: 1 0 100%;
  }
}
html.cooper .findoverlay__inputs--vehicle .wh-form__label, html.avon .findoverlay__inputs--vehicle .wh-form__label, html.cooper .findoverlay__inputs--location .wh-form__label, html.avon .findoverlay__inputs--location .wh-form__label {
  font-size: 16px;
  line-height: 100%;
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  margin: 0;
  color: #4C5760;
  pointer-events: none;
}
.findoverlay__inputs--vehicle .label-divider, .findoverlay__inputs--location .label-divider {
  font-size: 10px;
  line-height: 100%;
  color: #FF0014;
  position: relative;
  top: -2px;
  margin: 0 3px;
}
.findoverlay__inputs--vehicle .wh-form__fieldgroup, .findoverlay__inputs--location .wh-form__fieldgroup {
  margin: 0;
}
html.cooper[dir=rtl] .findoverlay__inputs--vehicle .wh-form__label, html.avon[dir=rtl] .findoverlay__inputs--vehicle .wh-form__label, html.cooper[dir=rtl] .findoverlay__inputs--location .wh-form__label, html.avon[dir=rtl] .findoverlay__inputs--location .wh-form__label {
  left: 0;
  right: 65px;
}
.findoverlay__inputs--size {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-right: 20px;
}
html[dir=rtl] .findoverlay__inputs--size {
  padding-left: 20px;
  padding-right: 0;
}
html.cooper .findoverlay__inputs--size .wh-form__label, html.avon .findoverlay__inputs--size .wh-form__label {
  position: absolute;
  top: -5px;
  transform: translateY(-100%);
}
html.cooper .findoverlay__inputs--size .wh-form__label {
  font-size: 18px;
  line-height: 100%;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #9B9B9B;
  font-weight: 400;
}
.findoverlay__input {
  position: relative;
}
.findoverlay__input--select {
  flex: 0 0 calc(33.3333% - 10px);
}
.findoverlay__input--select .wh-form__fieldline {
  margin-top: 0;
}
.findoverlay__input--select .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-button.ui-button,
.findoverlay__input--select .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-button.ui-button:active {
  height: 80px;
}
.findoverlay__input--select .wh-form__fieldgroup--pulldown .wh-form__fieldline {
  margin-top: 0;
}
html.findoverlay-show-application .findoverlay__input--select {
  flex: 0 0 calc(25% - 10px);
}
.findoverlay__input--precondition-first {
  opacity: 0.5;
  pointer-events: none;
}
.findoverlay__input .wh-form__fieldgroup--textedit input[type=text] {
  padding-left: 100px;
}
html[dir=rtl] .findoverlay__input .wh-form__fieldgroup--textedit input[type=text] {
  padding-right: 100px;
  padding-left: 15px;
}
html:not(.findoverlay-show-application) .findoverlay__input__application {
  display: none;
}
.findoverlay__input-search-icon {
  position: absolute;
  top: 50%;
  left: 26px;
  transform: translateY(-50%);
  margin: 4px 0 0;
  pointer-events: none;
}
html[dir=rtl] .findoverlay__input-search-icon {
  left: 0;
  right: 26px;
}
.findoverlay__inputs .wh-form__fieldline input[type=text] {
  height: 80px;
}
.findoverlay__legendaimg {
  position: absolute;
  top: calc(100% + 13px);
  height: 290px;
  left: -66px;
}
.findoverlay__searchbutton {
  width: 100%;
}
html.cooper .findoverlay__searchbutton, html.avon .findoverlay__searchbutton {
  height: 80px;
}
@media (max-width: 700px) {
  html.cooper .findoverlay__searchbutton, html.avon .findoverlay__searchbutton {
    width: 120px;
    height: 50px;
  }
}
.findoverlay__examplecontainer {
  font-size: 18px;
  line-height: 100%;
  display: flex;
  text-transform: uppercase;
  padding: 24px 0;
}
@media (max-width: 700px) {
  .findoverlay__examplecontainer {
    flex: 1 1 50%;
  }
}
@media (min-width: 701px) {
  .findoverlay__examplecontainer {
    order: 9;
    flex: 1 0 100%;
  }
}
.findoverlay__example-label {
  margin-right: 6px;
}
html[dir=rtl] .findoverlay__example-label {
  margin-left: 6px;
  margin-right: 0;
}
.findoverlay__bgoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  z-index: 190;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  background: rgba(0, 0, 0, 0.8);
}
html.header-find-container-active .findoverlay__bgoverlay {
  opacity: 1;
  pointer-events: auto;
}
.findoverlay__mobileheader {
  font-size: 30px;
  line-height: 100%;
  display: none;
  letter-spacing: 1.08px;
  margin: 0 0 24px;
}
html.cooper .findoverlay__mobileheader {
  color: #001d60;
}
html.avon .findoverlay__mobileheader {
  color: #fff;
}
html.cooper .findoverlay__mobileheader {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .findoverlay__mobileheader {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
html.cooper .findoverlay .button__title, html.avon .findoverlay .button__title {
  min-width: 140px;
}
html.avon .findoverlay__tab, html.avon .findoverlay__tabdivider {
  margin-right: 20px;
}
html.avon .findoverlay__tab {
  font-size: 20px;
  line-height: 100%;
  color: #A0A0A0;
  font-weight: 300;
  text-transform: uppercase;
}
html.avon .findoverlay__tab.selected {
  color: #000;
  font-weight: 700;
}
html.avon .findoverlay__legendaimg {
  top: calc(100% + 37px);
  height: 225px;
  left: -86px;
}
html.avon .findoverlay__input-search-icon svg .svg-search-blue {
  fill: #000;
}
html.avon .findoverlay__inputs--vehicle .wh-form__label, html.avon .findoverlay__inputs--location .wh-form__label {
  font-size: 16px;
  line-height: 100%;
  color: #A0A0A0;
  font-weight: 600;
}
html.avon .findoverlay__searchcol {
  display: none;
}
html.avon .findoverlay__searchbutton .button__title {
  font-weight: 700;
  font-size: 20px;
}
html.avon .findoverlay__ql-icon svg .svg-quicklinks-fill {
  fill: #000;
}
html.avon .findoverlay__ql-icon svg .svg-quicklinks-stroke {
  stroke: #000;
}
html.avon .findoverlay__locationmarker svg .svg-markerfilled {
  fill: #ED0000;
}
@media screen and (max-width: 1150px) {
  .findoverlay {
    min-height: 650px;
  }
  .findoverlay__legendaimg {
    top: calc(100% + 7px);
    height: 230px;
    left: -70px;
  }
  html.avon .findoverlay__legendaimg {
    top: calc(100% + 71px);
    height: 155px;
    left: -16px;
  }
}
@media screen and (max-width: 1024px) {
  .findoverlay__legendaimg {
    top: calc(100% + 27px);
    height: 210px;
    left: -60px;
  }
}
@media screen and (max-width: 1000px) {
  .findoverlay__legendaimg {
    display: none;
  }
}
@media screen and (max-width: 920px) {
  .findoverlay__gridleft {
    border-right: 0;
    padding-left: 0;
  }
  html[dir=rtl] .findoverlay__gridleft {
    border-left: 0;
  }
  .findoverlay__gridright {
    display: none;
  }
  html.cooper .findoverlay .button,
  html.cooper .findoverlay .button__title, html.avon .findoverlay .button,
  html.avon .findoverlay .button__title {
    min-width: 100px;
  }
}
@media screen and (max-width: 768px) {
  html.cooper .findoverlay .findoverlay__legendaimg, html.avon .findoverlay .findoverlay__legendaimg {
    display: block;
  }
}
@media screen and (max-width: 700px) {
  .findoverlay {
    padding-top: 100px;
  }
  .findoverlay__gridcontainer {
    position: relative;
  }
  .findoverlay__tabs {
    margin-bottom: 50px;
  }
  .findoverlay__tabcontainer[data-type=size] .findoverlay__searchbuttoncontainer {
    padding-top: 0;
  }
  .findoverlay__tab {
    font-size: 20px;
    line-height: 100%;
  }
  .findoverlay__tab:after {
    top: calc(100% + 2px);
  }
  .findoverlay__tablabel--desktop {
    display: none;
  }
  .findoverlay__tablabel--mobile {
    display: inline;
  }
  .findoverlay__mobileheader {
    display: block;
  }
  .findoverlay__inputs {
    margin-bottom: 10px;
  }
  .findoverlay__inputs--size {
    padding-right: 0;
    margin-bottom: 0;
    display: block;
  }
  html.cooper .findoverlay__inputs--size .wh-form__label, html.avon .findoverlay__inputs--size .wh-form__label {
    position: static;
    transform: none;
  }
  html.cooper .findoverlay__inputs--size .wh-form__fieldgroup, html.avon .findoverlay__inputs--size .wh-form__fieldgroup {
    margin-bottom: 15px !important;
  }
  .findoverlay__input--last .wh-form__fieldgroup--pulldown {
    margin-bottom: 14px;
  }
  .findoverlay__input--select .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-button.ui-button,
  .findoverlay__input--select .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-button.ui-button:active {
    height: 60px;
    padding-left: 13px;
  }
  .findoverlay__searchbuttoncontainer {
    align-self: center;
    margin-left: 20px;
  }
  .findoverlay__searchbuttoncontainer--alignright {
    width: 100%;
    text-align: right;
  }
  .findoverlay__searchbutton {
    min-width: 0 !important;
  }
  .findoverlay__examplecontainer {
    font-size: 12px;
    position: static;
    left: 13px;
    bottom: 0;
    height: auto;
    align-items: center;
    letter-spacing: 0;
  }
  .findoverlay__gridleft {
    padding-right: 0;
  }
  .findoverlay__inputs--size {
    flex-direction: column;
  }
  html.avon .findoverlay .button__title {
    font-size: 18px;
  }
  html.cooper .findoverlay .findoverlay__legendaimg, html.avon .findoverlay .findoverlay__legendaimg {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .findoverlay__input .wh-form__fieldgroup--textedit input[type=text] {
    padding-left: 65px;
  }
  html.cooper .findoverlay__inputs--vehicle .wh-form__label, html.avon .findoverlay__inputs--vehicle .wh-form__label, html.cooper .findoverlay__inputs--location .wh-form__label, html.avon .findoverlay__inputs--location .wh-form__label {
    left: 65px;
  }
  html.avon .findoverlay__inputs--vehicle .wh-form__label, html.avon .findoverlay__inputs--location .wh-form__label {
    font-size: 12px;
  }
  html.cooper .findoverlay__tab, html.cooper .findoverlay__tabdivider, html.avon .findoverlay__tab, html.avon .findoverlay__tabdivider {
    margin-right: 15px;
  }
  html.avon .findoverlay__tab, html.avon .findoverlay__tabdivider {
    font-size: 16px;
    line-height: 100%;
  }
  html.avon .findoverlay--findstore .findoverlay__tab,
  html.avon .findoverlay--findstore .findoverlay__tabdivider {
    font-size: 13px;
    line-height: 100%;
  }
  html.avon .findoverlay--findstore .findoverlay__locationmarker svg {
    height: 20px;
  }
}
@media screen and (max-width: 370px) {
  html.avon .findoverlay--findstore .findoverlay__locationmarker {
    display: none;
  }
}
.findoverlay .c-avontypes {
  /*
      @media(max-width: 768px)
      {
        &__blocks
        {
          flex-wrap: wrap;
          width: 400px;
        }
      }

      @media(max-width: 400px)
      {
        &__blocks
        {
          width: 200px;
        }
      }
  */
}
.findoverlay .c-avontypes__title {
  font-size: 24px;
  line-height: 100%;
  color: #000;
  text-transform: uppercase;
  margin: 0 0 85px;
}
html.cooper .findoverlay .c-avontypes__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .findoverlay .c-avontypes__title {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
.findoverlay .c-avontypes__blocks {
  display: flex;
}
.findoverlay .c-avontypes__block {
  position: relative;
  width: 234px;
  height: 176px;
  transition: background 0.3s;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  margin: 0 24px 24px 0;
}
.findoverlay .c-avontypes__block:before, .findoverlay .c-avontypes__block:after {
  content: "";
  position: absolute;
}
.findoverlay .c-avontypes__block:before {
  top: 0;
  left: 0;
  width: 0%;
  transition: width 0.3s;
  height: 4px;
  background: #ED0000;
}
html.non-touch .findoverlay .c-avontypes__block:hover {
  background: #F4F5F7;
}
html.non-touch .findoverlay .c-avontypes__block:hover:before {
  width: 100%;
}
.findoverlay .c-avontypes__block-icon {
  height: 38px;
  margin: 0 0 15px;
}
.findoverlay .c-avontypes__block-title {
  font-size: 16px;
  line-height: 100%;
  color: #000000;
  text-transform: uppercase;
}
html.cooper .findoverlay .c-avontypes__block-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .findoverlay .c-avontypes__block-title {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: inherit;
}
html.findoverlay-avon-enabled .findoverlay .c-avontypes {
  display: none;
}
@media screen and (max-width: 800px) {
  .findoverlay .c-avontypes__blocks {
    flex-flow: row wrap;
  }
  .findoverlay .c-avontypes__block {
    width: 50%;
    margin: 0;
    box-shadow: none;
  }
}
@media screen and (max-width: 700px) {
  .findoverlay .c-avontypes__title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 370px) {
  .findoverlay .c-avontypes__block {
    width: 100%;
  }
}

html.avon.findoverlay-avon-enable-categories .findoverlay__searchcol--avon-motorsport {
  display: block;
}

html.findoverlay-avon-enable-search .findoverlay__searchcol--avon-other {
  display: block;
}

html.findoverlay-search-enabled .findoverlay__gridright {
  width: 70px;
}
html.findoverlay-search-enabled .findoverlay__ql-title {
  top: 123px;
}
html.findoverlay-search-enabled .findoverlay__ql-title a, html.findoverlay-search-enabled .findoverlay__ql-icon {
  pointer-events: auto;
}
html.findoverlay-search-enabled .findoverlay__ql-links {
  opacity: 0;
  pointer-events: none;
}
html.findoverlay-search-enabled .findoverlay__ql-icon {
  opacity: 1;
}
html.findoverlay-search-enabled .findoverlay__legendaimg {
  left: 41px;
  width: 84%;
}
@media screen and (max-width: 1400px) {
  html.findoverlay-search-enabled .findoverlay__gridleft {
    width: 1000px;
    max-width: 75vw;
  }
}
@media screen and (max-width: 1000px) {
  html.findoverlay-search-enabled .findoverlay__ql-title {
    display: none;
  }
}
@media screen and (max-width: 920px) {
  html.findoverlay-search-enabled .findoverlay__gridleft {
    max-width: initial;
  }
}
html.findoverlay-search-enabled.avon.header-find-tires-container-active .findoverlay__ql-icon, html.findoverlay-search-enabled.avon.header-find-tires-container-active .findoverlay__ql-title {
  transform: translateY(44px);
}

html.avon.findoverlay-search-enabled .findoverlay__gridright {
  width: 140px;
}

html.avon.header-find-store-container-active .findoverlay__searchcol {
  display: block;
}

#motorsportcategories {
  list-style-type: none;
  margin: 0 0 0 -18px;
  padding: 0;
  display: block;
}
#motorsportcategories:after {
  clear: both;
  content: "";
  display: block;
}
#motorsportcategories li {
  float: left;
  width: 25%;
  height: 70px;
  margin-bottom: 20px;
  padding-left: 18px;
}
#motorsportcategories li a {
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  padding: 20px 40px 20px 20px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
  position: relative;
}
#motorsportcategories li a:hover {
  text-decoration: underline;
}
#motorsportcategories li a > .arrow {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -8px;
}
#motorsportcategories li a > .arrow .svg-gt {
  stroke: #EB0328;
}
@media (max-width: 900px) {
  #motorsportcategories li {
    width: 33.3333%;
  }
}
@media (max-width: 750px) {
  #motorsportcategories li {
    width: 50%;
  }
}
@media (max-width: 400px) {
  #motorsportcategories li a {
    font-size: 13px;
    line-height: 15px;
    padding: 20px 40px 20px 20px;
  }
}

html.findoverlay-avon--motorcycles .findoverlay__not-motorcycle,
html:not(.findoverlay-avon--motorcycles) .findoverlay__motorcycle {
  display: none;
}