.twocolumnstext__cols {
  display: flex;
  justify-content: space-between;
}
.twocolumnstext__col {
  width: calc(50% - 12px);
}
.twocolumnstext__title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 36px;
  margin: 0 0 1.875rem;
}
html.cooper .twocolumnstext__title {
  color: #001d60;
}
html.avon .twocolumnstext__title {
  color: #fff;
}
html.avon .twocolumnstext__title {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
html.cooper .twocolumnstext .wh-rtd {
  color: #5C666F;
}
html.avon .twocolumnstext .wh-rtd {
  color: inherit;
}
@media (max-width: 768px) {
  .twocolumnstext__title, .twocolumnstext__cols {
    padding-left: 20px;
    padding-right: 20px;
  }
  .twocolumnstext__cols {
    flex-direction: column;
  }
  .twocolumnstext__col {
    width: 100%;
  }
}