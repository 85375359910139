.lineupslideshow {
  padding: 36px 23px;
  display: none;
  margin: 0 0 35px;
  position: relative;
}
.lineupslideshow:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80vw;
}
html.cooper .lineupslideshow:before {
  background-color: #001d60;
}
html.avon .lineupslideshow:before {
  background-color: #fff;
}
html[dir=rtl] .lineupslideshow:before {
  right: 0;
  left: auto;
}
.lineupslideshow.enabled {
  display: block;
}
.lineupslideshow__navigation {
  position: relative;
  margin: 0 0 28px;
  display: flex;
}
.lineupslideshow__navigation .swiper-button-next,
.lineupslideshow__navigation .swiper-button-prev {
  position: static;
  top: 0;
  margin-top: 0;
  background: none;
  width: auto;
  height: auto;
  margin-right: 18px;
}
html[dir=rtl] .lineupslideshow__navigation .swiper-button-next,
html[dir=rtl] .lineupslideshow__navigation .swiper-button-prev {
  margin-left: 18px;
  margin-right: 0;
}
.lineupslideshow__navigation .swiper-button-prev {
  transform: scaleX(-1);
}
.lineupslideshow .circlearrow {
  display: block;
}
html.cooper .lineupslideshow .tireimageblock {
  background-image: linear-gradient(205deg, #373737 35%, #000000 100%);
}