.news {
  margin: 30px 0;
}
.news .contentwrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.news__container {
  width: calc(50% - 12px);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 605px;
}
.news__container--right {
  justify-content: space-between;
}
.news__top-right-image-container {
  position: relative;
}
.news__top-right-image-container .imagetextblock__image-container {
  display: block;
  width: 100%;
  height: 250px;
}
.news__bottom-right-title {
  font-size: 36px;
  line-height: 100%;
  margin-bottom: 0.75rem;
}
html.cooper .news__bottom-right-title {
  color: #001d60;
}
html.avon .news__bottom-right-title {
  color: #fff;
}
html.cooper .news__bottom-right-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
html.avon .news__bottom-right-title {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.news__top-right-title-overlay {
  background-color: rgba(216, 216, 216, 0.44);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 95px;
  display: flex;
  color: #fff;
  padding-left: 70px;
  align-items: center;
  font-size: 36px;
  line-height: 100%;
  z-index: 100;
}
html.cooper .news__top-right-title-overlay {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
html.avon .news__top-right-title-overlay {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html[dir=rtl] .news__top-right-title-overlay {
  padding-left: 0;
  padding-right: 70px;
}
.news__bottom-right-container {
  background: #F4F5F7;
  height: 250px;
  padding: 28px 70px;
}
html.cooper .news {
  padding-bottom: 2.2rem;
}
html.cooper .news__container--right {
  height: calc(605px - 2.2rem);
}
html.cooper .news__top-right-button {
  position: absolute;
  bottom: -2.2rem;
  right: 15px;
}
html.avon .news__container--right {
  overflow: hidden;
}
html.avon .news__top-right-title-overlay {
  min-height: 95px;
  height: auto;
}
html.avon .news .imagetextblock__textcontainer {
  background-color: #f4f5f7;
}
html.avon .news__top-right-image-container {
  overflow: hidden;
}
html.avon .news__top-right-image-container .button {
  position: absolute;
  bottom: -2px;
  left: auto;
}
html.avon .news__bottom-right-container {
  height: 320px;
}
html.avon .news__bottom-right-container .button {
  bottom: -2px;
  right: -30px;
  border: 0;
  left: auto;
}
html.avon[dir=rtl] .news .news__top-right-image-container .button {
  left: -30px;
  right: auto;
}
html.avon[dir=rtl] .news .news__bottom-right-container .button {
  left: -30px;
}
@media (max-width: 1024px) {
  .news__bottom-right-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 768px) {
  .news .contentwrapper {
    width: 100%;
    flex-direction: column;
  }
  .news__container {
    width: 100%;
    margin-bottom: 40px;
  }
  .news__top-right-button {
    left: 20px;
    right: 20px;
  }
  .news .newsletter__form .button {
    left: 20px;
    width: calc(100% - 2 * 20px);
  }
  html.cooper .news__bottom-right-container, html.avon .news__bottom-right-container {
    position: relative;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 576px) {
  .news__top-right-title-overlay {
    padding-left: 30px;
    padding-right: 30px;
  }
}