.footer {
  padding: 5.3125rem 0;
  background: var(--footer-background);
}
.footer__columns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 40px 0 17px;
}
.footer__columns--languagesocial {
  padding: 0 17px 80px;
  margin: 0 0 60px;
  border-bottom: 1px dashed #001D60;
}
.footer__columns--links {
  justify-content: space-between;
}
html[dir=rtl] .footer__columns {
  padding-right: 17px;
  padding-left: 40px;
}
.footer__column {
  width: 33.3333333333%;
}
.footer__col-title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.footer__col-title--policies {
  justify-content: center;
}
.footer__col-title--company {
  justify-content: flex-end;
}
.footer__col-icon, .footer__col-links-title {
  height: 50px;
  display: flex;
  align-items: center;
}
.footer__col-icon {
  display: flex;
  align-items: center;
  width: 60px;
}
.footer__col-links-title {
  font-size: 24px;
  line-height: 100%;
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--footer-heading-color);
}
.footer__col-links {
  display: flex;
  flex-direction: column;
  margin: 5px 0 85px;
}
html.cooper .footer__col-link, html.avon .footer__col-link {
  font-size: 20px;
  line-height: 35px;
  color: #9B9B9B;
  text-decoration: none;
  text-transform: initial;
  text-underline-position: under;
}
html.cooper .footer__col-link:hover, html.avon .footer__col-link:hover {
  text-decoration: underline;
}
.footer__newsletter-container {
  margin: 0 0 2.5rem;
}
.footer__newsletter {
  font-size: 24px;
  line-height: 100%;
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}
html.cooper .footer__newsletter {
  color: #001d60;
}
html.avon .footer__newsletter {
  color: #fff;
}
html.non-touch .footer__newsletter:hover {
  text-decoration: underline;
}
.footer__socialmedia {
  display: flex;
  align-items: center;
  margin-left: 70px;
}
html[dir=rtl] .footer__socialmedia {
  margin-left: 0;
  margin-right: 70px;
}
.footer__social-icon {
  margin: 0 2.5rem 0 0;
}
html.avon .footer__social-icon svg g,
html.avon .footer__social-icon .svg-youtube2 {
  fill: #333;
}
html[dir=rtl] .footer__social-icon {
  margin-left: 2.5rem;
  margin-right: 0;
}
.footer__languages {
  display: flex;
  align-items: center;
  margin-bottom: 5.75rem;
}
.footer__flaglink {
  position: relative;
  top: 2px;
}
.footer__flag {
  height: 21px;
  margin: 0 0.9375rem 0 0;
  display: block;
}
html[dir=rtl] .footer__flag {
  margin-left: 0.9375rem;
  margin-right: 0;
}
.footer__language {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 100%;
  margin: 0 1.6875rem 0 0;
}
html.cooper .footer__language {
  color: #001d60;
}
html.avon .footer__language {
  color: #fff;
}
html[dir=rtl] .footer__language {
  margin-left: 27px;
  margin-right: 0;
}
.footer__change-language {
  display: none;
}
html.cooper .footer__change-language, html.avon .footer__change-language {
  font-size: 14px;
  line-height: 100%;
  text-decoration: none;
  color: #9B9B9B;
}
html.non-touch html.cooper .footer__change-language:hover, html.non-touch html.avon .footer__change-language:hover {
  text-decoration: underline;
}
.footer__blocks {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footer__block {
  width: calc(25% - 21px);
  display: block;
  text-decoration: none;
  height: 270px;
  position: relative;
  overflow: hidden;
}
html.non-touch .footer__block:hover .footer__block-image {
  transform: scale(1.1);
}
.footer__block-image, .footer__block-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.footer__block-image {
  transition: transform 0.3s;
}
.footer__block-overlay {
  background: rgba(0, 29, 96, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer__block-title {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 100%;
  color: #fff;
}
.footer__block-icon {
  height: 42px;
  margin: 0 0 10px;
}
.footer__block-icon svg {
  display: block;
}
.footer__bottomnav {
  padding: 20px 0;
  color: #333;
}
.footer__bottomnav__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
html.avon .footer {
  padding: 0;
}
html.avon .footer__content {
  padding-top: clamp(55px, 8dvw, 80px);
  padding-bottom: clamp(55px, 8dvw, 80px);
  border-bottom: 1px solid #333;
}
html.avon .footer__socialmedia {
  flex: none;
  margin-left: 0;
}
html.avon .footer__since1904 {
  margin: clamp(30px, 4dvw, 40px) 0;
}
html.avon .footer__since1904 img {
  width: 219px;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}
html.avon .footer__columns {
  padding: 0;
  justify-content: space-between;
}
html.avon .footer__column {
  flex: none;
  width: calc(33.3333% - 16px);
  display: flex;
  flex-flow: column;
}
html.avon .footer__col-icon {
  flex: none;
  width: auto;
  height: auto;
  margin-right: clamp(16px, 2.4dvw, 24px);
  position: relative;
  top: -3px;
}
html.avon .footer__col-icon svg, html.avon .footer__col-icon img {
  height: 32px;
  width: auto;
}
html.avon .footer__col-links-title {
  font-family: var(--font-heading);
  margin-bottom: clamp(5px, 2.5dvw, 25px);
  height: auto;
  font-size: clamp(24px, 3.2dvw, 32px);
}
html.avon .footer__col-links {
  margin: 0;
  flex: 1;
}
html.avon .footer__col-link {
  font-size: clamp(12px, 1.6dvw, 16px);
  color: #333;
  line-height: 165%;
  margin-top: 10px;
}
html.avon .footer__col-icon > svg g {
  fill: #ED0000;
}
@media screen and (max-width: 768px) {
  html.avon .footer__columns {
    justify-content: flex-start;
  }
  html.avon .footer__column {
    width: calc(50% - 12px);
  }
  html.avon .footer__column:first-child {
    margin-right: 24px;
  }
  html.avon .footer__column--company {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  html.avon .footer__columns {
    justify-content: flex-start;
  }
  html.avon .footer__column {
    width: 100%;
  }
  html.avon .footer__column:first-child {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1023px) {
  .footer__columns {
    flex-wrap: wrap;
  }
  .footer__column {
    width: calc(50% - 12px);
  }
  .footer__col-links {
    margin-bottom: 2.5rem;
  }
  .footer__col-title {
    margin-bottom: 1.25rem;
  }
  .footer__col-title--company {
    justify-content: flex-start;
  }
  .footer__languages {
    margin-bottom: 2.5rem;
  }
  .footer__blocks {
    flex-wrap: wrap;
  }
  .footer__block {
    width: calc(50% - 12px);
    margin-bottom: 25px;
  }
}
@media (max-width: 768px) {
  .footer .contentwrapper {
    padding-left: 8.3333333333vw;
    padding-right: 8.3333333333vw;
  }
  .footer__columns {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 576px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 0;
  }
  .footer__columns, .footer__languages {
    padding-left: 8.3333333333vw;
  }
  html[dir=rtl] .footer__columns, html[dir=rtl] .footer__languages {
    padding-right: 8.3333333333vw;
    padding-left: 0;
  }
  .footer__column {
    width: 100%;
  }
  .footer__col-title {
    margin-bottom: 0;
  }
  .footer__col-title--policies {
    justify-content: flex-start;
  }
  .footer__newsletter-container {
    margin-top: 0.9375rem;
    margin-bottom: 1.5625rem;
  }
  .footer__newsletter {
    text-transform: initial;
  }
  .footer__socialmedia {
    margin: 50px 0 0;
  }
  .footer__blocks {
    padding: 0 13px 0;
  }
  .footer__block {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
  }
  .footer .contentwrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 400px) {
  .footer__socialmedia {
    margin-left: 0;
  }
}