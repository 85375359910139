@charset "UTF-8";
.wh-form {
  margin-bottom: 45px;
  /* fully css styled pulldown, see dealer selector on registration page */
}
.wh-form .wh-rtd__body {
  padding: 5px 10px;
}
.wh-form__rtd {
  min-height: 200px;
}
.wh-form__rtd#dealerusereditform-description {
  min-height: 400px;
}
.wh-form textarea {
  resize: vertical;
}
.wh-form__imgedit {
  width: 200px;
  height: 200px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
}
.wh-form__imgeditdelete {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  font-family: "FontAwesome";
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.2;
  transition: opacity 0.5s;
  cursor: pointer;
}
.wh-form__imgeditdelete:before {
  content: "\f1f8";
}
.wh-form__imgeditdelete:hover {
  opacity: 1;
}
.wh-form__imgeditholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
}
.wh-form__imgeditholder:before, .wh-form__imgeditholder:after {
  color: #999;
  display: block;
  width: 100%;
  flex: none;
  text-align: center;
  padding: 0 10px;
}
.wh-form__imgeditholder:before {
  content: "Click here to upload image";
}
html[lang=de] .wh-form__imgeditholder:before {
  content: "Klicken Sie hier, um ein Bild hochzuladen";
}
.wh-form__imgeditholder:after {
  font-size: 95%;
  content: "(Minimum size 512x512px)";
}
html[lang=de] .wh-form__imgeditholder:after {
  content: "(minimale Größe 512x512px)";
}
.wh-form__imgedit#dealerusereditform-logo .wh-form__imgeditholder:after {
  content: "(Minimum width 128px)";
}
html[lang=de] .wh-form__imgedit#dealerusereditform-logo .wh-form__imgeditholder:after {
  content: "(minimale Breite 128px)";
}
.wh-form__imgedit#dealerusereditform-header_image {
  width: 100%;
}
.wh-form__imgedit#dealerusereditform-header_image .wh-form__imgeditholder:after {
  content: "(Minimum size 1600x900px)";
}
html[lang=de] .wh-form__imgedit#dealerusereditform-header_image .wh-form__imgeditholder:after {
  content: "(minimale Größe 1600x900px)";
}
.wh-form__imgedit--hasimage .wh-form__imgeditholder:before, .wh-form__imgedit--hasimage .wh-form__imgeditholder:after {
  display: none;
}
.wh-form__label {
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  margin: 0 0 0.625rem;
}
html.cooper .wh-form__label {
  color: #001d60;
}
html.avon .wh-form__label {
  color: #fff;
}
.wh-form__fieldline input[type=text],
.wh-form__fieldline input[type=email],
.wh-form__fieldline input[type=url],
.wh-form__fieldline input[type=date],
.wh-form__fieldline input[type=search],
.wh-form__fieldline input[type=number],
.wh-form__fieldline input[type=password],
.wh-form__fieldline input[type=time],
.wh-form__fieldline textarea {
  font-size: 1rem;
  background: #fff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
  height: 4.4rem;
  border: 1px solid #fff;
  padding: 0 0.9375rem;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
}
html.cooper .wh-form__fieldline input[type=text],
html.cooper .wh-form__fieldline input[type=email],
html.cooper .wh-form__fieldline input[type=url],
html.cooper .wh-form__fieldline input[type=date],
html.cooper .wh-form__fieldline input[type=search],
html.cooper .wh-form__fieldline input[type=number],
html.cooper .wh-form__fieldline input[type=password],
html.cooper .wh-form__fieldline input[type=time],
html.cooper .wh-form__fieldline textarea {
  color: #5C666F;
}
html.avon .wh-form__fieldline input[type=text],
html.avon .wh-form__fieldline input[type=email],
html.avon .wh-form__fieldline input[type=url],
html.avon .wh-form__fieldline input[type=date],
html.avon .wh-form__fieldline input[type=search],
html.avon .wh-form__fieldline input[type=number],
html.avon .wh-form__fieldline input[type=password],
html.avon .wh-form__fieldline input[type=time],
html.avon .wh-form__fieldline textarea {
  color: inherit;
}
.wh-form__fieldline input[type=text]::placeholder,
.wh-form__fieldline input[type=email]::placeholder,
.wh-form__fieldline input[type=url]::placeholder,
.wh-form__fieldline input[type=date]::placeholder,
.wh-form__fieldline input[type=search]::placeholder,
.wh-form__fieldline input[type=number]::placeholder,
.wh-form__fieldline input[type=password]::placeholder,
.wh-form__fieldline input[type=time]::placeholder,
.wh-form__fieldline textarea::placeholder {
  text-transform: uppercase;
}
html.cooper .wh-form__fieldline input[type=text]::placeholder,
html.cooper .wh-form__fieldline input[type=email]::placeholder,
html.cooper .wh-form__fieldline input[type=url]::placeholder,
html.cooper .wh-form__fieldline input[type=date]::placeholder,
html.cooper .wh-form__fieldline input[type=search]::placeholder,
html.cooper .wh-form__fieldline input[type=number]::placeholder,
html.cooper .wh-form__fieldline input[type=password]::placeholder,
html.cooper .wh-form__fieldline input[type=time]::placeholder,
html.cooper .wh-form__fieldline textarea::placeholder {
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
html.avon .wh-form__fieldline input[type=text]::placeholder,
html.avon .wh-form__fieldline input[type=email]::placeholder,
html.avon .wh-form__fieldline input[type=url]::placeholder,
html.avon .wh-form__fieldline input[type=date]::placeholder,
html.avon .wh-form__fieldline input[type=search]::placeholder,
html.avon .wh-form__fieldline input[type=number]::placeholder,
html.avon .wh-form__fieldline input[type=password]::placeholder,
html.avon .wh-form__fieldline input[type=time]::placeholder,
html.avon .wh-form__fieldline textarea::placeholder {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.wh-form__fieldline textarea {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 115px;
}
.wh-form__fieldline select {
  -webkit-appearance: none;
  appearance: none;
}
.wh-form__pulldown-styled {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
  font-size: 18px;
  color: #454545;
  height: 4.4rem;
  overflow: hidden;
  background-color: #fff;
}
.wh-form__pulldown-styled .arrow {
  font-size: 34px;
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -17px;
  color: rgb(0, 29, 96);
}
.wh-form__pulldown-styled:after {
  display: block;
  content: "";
  width: 20px;
  position: absolute;
  z-index: 2; /* On top of select */
  right: 40px;
  top: 5px;
  bottom: 5px;
  pointer-events: none;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=1 ); /* IE6-9 */
}
.wh-form__pulldown-styled select {
  position: relative;
  z-index: 1; /* On top of arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: transparent;
  border: 0 none;
  height: 4.4rem;
  width: 100%;
  color: inherit;
  padding-right: 40px !important;
}
.wh-form__pulldown-styled select:disabled {
  opacity: 0.4;
}
.wh-form__pulldown-styled select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.wh-form__fieldgroup {
  margin: 0 0 30px;
}
.wh-form__fieldgroup--checkboxgroup input[type=checkbox] {
  margin: 0;
  position: relative;
  top: -2px;
}
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline .wh-form__optiondata {
  padding-left: 10px;
}
.wh-form__fieldgroup--pulldown .wh-form__label {
  margin-bottom: 0;
}
.wh-form__fieldgroup--pulldown .wh-form__fieldline {
  position: relative;
  transition: border-color 0.3s;
  margin-top: 5px;
}
.wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-button.ui-button, .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-button.ui-button:active {
  font-size: 18px;
  line-height: 100%;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
  background: #fff;
  height: 4.4rem;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
  outline: none;
  border: 1px solid #fff;
  text-transform: uppercase;
  color: #454545;
}
html.avon .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-button.ui-button, html.avon .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-button.ui-button:active {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
  width: 19px;
  height: 12px;
  background: url("../../web/img/cooper-pulldownarrow.svg") no-repeat;
}
html.avon .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-icon {
  background-image: url("../../web/img/avon-pulldownarrow.svg");
}
html[dir=rtl] .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-icon {
  right: auto;
  left: 10%;
}
html[dir=rtl] .wh-form__fieldgroup--pulldown .wh-form__fieldline .ui-selectmenu-text {
  margin-left: 20px;
  margin-right: 0;
}
.wh-form__fieldgroup--pulldown .wh-form__fieldline::after {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% - 4px)) rotate(45deg);
  right: 10%;
  margin-bottom: 3px;
}
.wh-form__fieldgroup--radiogroup input[type=radio] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.wh-form__fieldgroup--radiogroup input[type=radio] + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: 0;
}
html[dir=rtl] .wh-form__fieldgroup--radiogroup input[type=radio] + label {
  right: 21px;
}
.wh-form__fieldgroup--radiogroup input[type=radio] + label + span label {
  position: relative;
  top: 6px;
  left: 34px;
}
html[dir=rtl] .wh-form__fieldgroup--radiogroup input[type=radio] + label + span label {
  right: 34px;
  left: 0;
}
.wh-form__fieldgroup--radiogroup input[type=radio] + label::before {
  content: "";
  border: 1px solid #5C666F;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: -12px;
  border-radius: 50%;
}
.wh-form__fieldgroup--radiogroup input[type=radio] + label::after {
  transition: opacity 0.3s;
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: -9px;
  left: 3px;
  background: #001d60;
  opacity: 0;
  border-radius: 50%;
}
html.avon .wh-form__fieldgroup--radiogroup input[type=radio] + label::after {
  background-color: #ED0000;
}
.wh-form__fieldgroup--radiogroup input[type=radio]:checked + label::after {
  opacity: 1;
}
.wh-form__fieldgroup--radiogroup .wh-form__fieldline {
  margin-bottom: 0.5rem;
}
.wh-form__fieldgroup--radiogroup .wh-form__optionlabel {
  cursor: pointer;
}
html.cooper .wh-form__fieldgroup--radiogroup .wh-form__optionlabel {
  color: #001d60;
}
html.avon .wh-form__fieldgroup--radiogroup .wh-form__optionlabel {
  color: #fff;
}
.wh-form__fieldgroup--checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.wh-form__fieldgroup--checkbox input[type=checkbox] + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: 0;
}
html[dir=rtl] .wh-form__fieldgroup--checkbox input[type=checkbox] + label {
  right: 21px;
}
.wh-form__fieldgroup--checkbox input[type=checkbox] + label + span label {
  position: relative;
  top: 6px;
  left: 34px;
}
html[dir=rtl] .wh-form__fieldgroup--checkbox input[type=checkbox] + label + span label {
  right: 34px;
  left: 0;
}
.wh-form__fieldgroup--checkbox input[type=checkbox] + label::before {
  content: "";
  border: 1px solid #5C666F;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: -12px;
}
.wh-form__fieldgroup--checkbox input[type=checkbox] + label::after {
  content: "";
  border: 1px solid #001d60;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -9px;
  left: 3px;
  background: #001d60;
  opacity: 0;
}
html.avon .wh-form__fieldgroup--checkbox input[type=checkbox] + label::after {
  border: 1px solid;
  background-color: #ED0000;
}
.wh-form__fieldgroup--checkbox input[type=checkbox]:checked + label::after {
  opacity: 1;
}
html.cooper .wh-form__fieldgroup--checkbox .wh-form__optionlabel {
  color: #001d60;
}
html.avon .wh-form__fieldgroup--checkbox .wh-form__optionlabel {
  color: #fff;
}
.wh-form__fieldgroup--checkbox .wh-form__optionlabel::selection {
  background: transparent;
}
.wh-form__two-inputs {
  display: flex;
  justify-content: space-between;
}
.wh-form__two-inputs .wh-form__fieldgroup {
  width: calc(50% - 3px);
}
.wh-form__buttongroup {
  margin-top: 25px;
}
.wh-form__buttongroup .wh-form__button {
  margin-right: 10px;
}
html[dir=rtl] .wh-form__buttongroup .wh-form__button {
  margin-left: 10px;
  margin-right: 0;
}
.wh-form__buttongroup .wh-form__button--next + .wh-form__button--submit {
  width: auto;
}
.wh-form__fields, .wh-form__fieldgroup--imgedit .wh-form__fieldline {
  display: flex;
  flex-direction: column;
}
.wh-form__fieldline, .wh-form__fieldgroup--imgedit .wh-form__imgedit {
  order: 2;
}
.wh-form__errorlist, .wh-form__fieldgroup--imgedit .wh-form__error {
  order: 1;
  margin: 0 0 6px;
}
.wh-form__errorlist {
  line-height: 120%;
}
.wh-form .wh-form__error {
  width: 100%;
  padding: 0;
  margin: 0;
  color: #EB0328;
  font: inherit;
  list-style-type: none;
}
.wh-form__fieldgroup__error { /* custom errors webforms */
  position: absolute;
  left: 15px;
  margin-top: -27px;
  top: 100%;
  color: #EB0328;
  font-size: 14px;
}
.wh-form__uploadfield {
  display: flex;
}
.wh-form__uploadfield input[type=text],
.wh-form__uploadfield input[type=email],
.wh-form__uploadfield input[type=url],
.wh-form__uploadfield input[type=date],
.wh-form__uploadfield input[type=search],
.wh-form__uploadfield input[type=number],
.wh-form__uploadfield input[type=password],
.wh-form__uploadfield input[type=time],
.wh-form__uploadfield textarea {
  background: none;
  box-shadow: none;
  order: 2;
  display: inline-block;
}
.wh-form__uploadfield .wh-form__uploadfieldselect {
  display: inline-block;
}

.ui-selectmenu-menu .ui-widget.ui-widget-content {
  padding: 0;
}
.ui-selectmenu-menu .ui-menu {
  max-height: 300px;
}
.ui-selectmenu-menu .ui-menu-item {
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 100%;
}
.ui-selectmenu-menu .ui-menu-item .ui-state-active,
.ui-selectmenu-menu .ui-menu-item .ui-state-focus {
  margin: 0;
}
.ui-selectmenu-menu .ui-menu-item:nth-child(even) .ui-menu-item-wrapper {
  background: whitesmoke;
}
.ui-selectmenu-menu .ui-menu-item .ui-menu-item-wrapper {
  height: 40px;
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.ui-selectmenu-menu .ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  background: #001d60;
  border-color: #001d60;
}
html.avon .ui-selectmenu-menu .ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  background: #ED0000;
  border-color: #ED0000;
}
html.avon .ui-selectmenu-menu .ui-menu-item {
  font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.ui-selectmenu-menu .select-tiresize--size {
  display: inline-block;
  min-width: 55px;
  margin-right: 20px;
}
.ui-selectmenu-menu .select-speedindex--value {
  display: inline-block;
  width: 30px;
}

html.page-formwebtool .wh-form__button--submit {
  width: 100%;
}

html.labelsinfields .wh-form__fieldgroup {
  margin-bottom: 15px;
  position: relative;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error input[type=text],
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error input[type=email],
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error input[type=url],
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error input[type=date],
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error input[type=time],
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error input[type=search],
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error input[type=number],
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error input[type=password],
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error textarea {
  border-color: #EB0328;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error .wh-form__label {
  top: calc(50% + 16px);
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--error input[type=text].wh-form__uploadfieldfilename {
  border: 0;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--active .wh-form__label {
  margin-left: -5px;
  margin-top: -20px;
  font-size: 11px;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--textarea .wh-form__label {
  top: 40px;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown) .wh-form__label {
  font-size: 18px;
  line-height: 100%;
  font-weight: 400;
  color: #5B666F;
  position: absolute;
  top: 50%;
  left: 21px;
  transform: translateY(-50%);
  transition: margin 0.2s, font-size 0.2s;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--checkbox .wh-form__label {
  margin-top: 6px;
  margin-left: 10px;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--upload .wh-form__label {
  margin-top: 20px;
  margin-bottom: 5px;
  position: static;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--radiogroup {
  margin-top: 10px;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--radiogroup .wh-form__label {
  position: static;
  margin-bottom: 0;
  transform: none;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--radiogroup .wh-form__optionlabel {
  top: 5px;
  left: 30px;
}
html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--date .wh-form__label, html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--time .wh-form__label {
  position: static;
  font-weight: 700;
}
html.cooper html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--date .wh-form__label, html.cooper html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--time .wh-form__label {
  color: #001d60;
}
html.avon html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--date .wh-form__label, html.avon html.labelsinfields .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown).wh-form__fieldgroup--time .wh-form__label {
  color: #fff;
}
html.labelsinfields .wh-form__fieldline textarea {
  padding-top: 30px;
  min-height: 150px;
}
html.labelsinfields .wh-form__optionlabel {
  max-width: 80vw;
  display: inline-block;
  cursor: pointer;
}
html.labelsinfields .wh-form__errorlist {
  margin: 0;
}

html.labelsinfields[dir=rtl] .wh-form__fieldgroup:not(.wh-form__fieldgroup--pulldown) .wh-form__label {
  left: auto;
  right: 31px;
}

html.labelsinfields.cooper .wh-form__fieldgroup--date .wh-form__label, html.labelsinfields.cooper .wh-form__fieldgroup--time .wh-form__label {
  color: #001d60;
}

html.labelsinfields.avon .wh-form__fieldgroup--date .wh-form__label, html.labelsinfields.avon .wh-form__fieldgroup--time .wh-form__label {
  color: #fff;
  font-weight: 400 !important;
}

.ui-selectmenu-menu.ui-front {
  z-index: 10000;
}