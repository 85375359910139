.regionsfoldout {
  max-width: 1104px;
  margin: 0 auto;
}
.regionsfoldout__title {
  margin-bottom: 50px;
}
.regionsfoldout__accordion {
  border-top: 1px solid #E2E2E2;
}
.regionsfoldout__question {
  font-size: 28px;
  line-height: 100%;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
html.cooper .regionsfoldout__question {
  color: #001d60;
}
html.avon .regionsfoldout__question {
  color: #fff;
}
.regionsfoldout__answer ul {
  list-style-type: none;
  margin: 0 0 0 -40px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.regionsfoldout__answer ul > li {
  display: inline-block;
  flex: 1 1 100%;
  max-width: 33.3333%;
  min-width: 33.3333%;
  padding-left: 40px;
  padding-bottom: 40px;
}
@media (max-width: 700px) {
  .regionsfoldout__answer ul > li {
    max-width: 50%;
    min-width: 50%;
  }
}
@media (max-width: 450px) {
  .regionsfoldout__answer ul > li {
    max-width: 100%;
  }
}
@media (max-width: 450px) {
  .regionsfoldout__answer ul {
    display: block;
  }
}
.regionsfoldout__answer ul .country__name {
  font-size: 24px;
  line-height: 100%;
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
}
html.cooper .regionsfoldout__answer ul .country__name {
  color: #001d60;
}
html.avon .regionsfoldout__answer ul .country__name {
  color: #fff;
}
.regionsfoldout__answer ul .country__name img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 32px;
  height: auto;
}
.regionsfoldout__answer ul .country__dealers h3 {
  font-size: 18px;
  line-height: 29px;
  font-family: inherit;
  margin: 0;
  padding-left: 15px;
  position: relative;
}
.regionsfoldout__answer ul .country__dealers h3::after {
  font-family: "FontAwesome";
  content: "\f105";
  font-size: 20px;
  position: absolute;
  left: 1px;
  top: 4px;
  display: inline-block;
  line-height: 20px;
}
.regionsfoldout__answer ul .country__dealers * + h3 {
  margin-top: 16px;
}
.regionsfoldout__answer ul .country__dealers p {
  font-size: 15px;
  line-height: 130%;
}
.regionsfoldout .ui-accordion-header {
  background: none;
  border: 0;
  border-bottom: 1px solid #E2E2E2;
  padding: 35px 140px 35px 94px;
  margin: 0 0 10px;
  outline: none;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  position: relative;
}
.regionsfoldout .ui-accordion-content {
  font-family: "Trade Gothic LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 40px 200px 0 0;
  border: 0;
  box-sizing: content-box;
}
.regionsfoldout .ui-accordion-content,
.regionsfoldout .ui-accordion-content p {
  margin: 0;
}
.regionsfoldout .ui-accordion-header,
.regionsfoldout .ui-accordion-content {
  padding-left: 100px;
}
.regionsfoldout .ui-accordion-header-icon {
  position: absolute;
  top: 50%;
  right: 94px;
  transform: translateY(-50%);
  width: 35px;
  height: 20px;
  background: url("../../web/img/cooper-faq-expand.svg") no-repeat;
  margin: 3px 0 0;
  transition: transform 0.4s;
  transform-origin: 50% 5px;
}
html[dir=rtl] .regionsfoldout .ui-accordion-header-icon {
  right: auto;
  left: 94px;
}
.regionsfoldout .ui-accordion-header-active .ui-accordion-header-icon {
  transform: scale(1, -1);
}
html.avon .regionsfoldout__title {
  font-size: 36px;
  line-height: 100%;
}
html.avon .regionsfoldout .ui-accordion-header-icon {
  background: url("../../web/img/avon-faq-expand.svg") no-repeat;
}
@media (max-width: 1024px) {
  .regionsfoldout .ui-accordion-header,
  .regionsfoldout .ui-accordion-content {
    padding-left: 50px;
    padding-right: 50px;
  }
  .regionsfoldout .ui-accordion-header-icon {
    right: 50px;
  }
}
@media (max-width: 768px) {
  .regionsfoldout .ui-accordion-header,
  .regionsfoldout .ui-accordion-content {
    padding-left: 0;
    padding-right: 0;
  }
  .regionsfoldout .ui-accordion-header-icon {
    right: 0;
  }
}