.quote {
  text-align: center;
  max-width: 728px;
  margin: 0 auto;
}
html.cooper .quote {
  color: #001d60;
}
html.avon .quote {
  color: #fff;
}
.quote__quotationmark {
  font-family: Rift, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 150px;
  line-height: 90px;
}
.quote__text {
  font-size: 60px;
  line-height: 65px;
  letter-spacing: 0.01px;
  margin: 0 0 30px;
}
.quote__text > *:first-child {
  margin-top: 0;
}
.quote__text > *:last-child {
  margin-bottom: 0;
}
.quote__from {
  font-size: 20px;
  line-height: 100%;
  font-weight: 700;
  color: #EB0328;
}